import { Badge, Col, Row, Table } from "antd"
import { useUpdateShortcutVizznUsers } from "../../apis/ShortcutCompliance"
import { useAuthContext } from "../../context/AuthContext"
import { ShortcutVizznUser } from "../../types/ShortcutCompliance"

export default function Users() {
  const { env, token } = useAuthContext()
  const vizznUsers = useUpdateShortcutVizznUsers({ env, token: token[env] })

  return (
    <Table dataSource={vizznUsers.data ? vizznUsers.data : []}>
      <Table.Column
        title="Working"
        dataIndex="working"
        key="working"
        render={(working: boolean) => (
          <Row justify="center">
            <Col>
              {working ? <Badge status="success" /> : <Badge status="error" />}
            </Col>
          </Row>
        )}
        sorter={(a: ShortcutVizznUser, b: ShortcutVizznUser) =>
          a.working === b.working ? 0 : a.working ? -1 : 1
        }
        defaultSortOrder="ascend"
      />
      <Table.Column title="Name" dataIndex="name" key="name" />
      <Table.Column title="Slack ID" dataIndex="slackID" key="slackID" />
      <Table.Column
        title="Shortcut ID"
        dataIndex="shortcutID"
        key="shortcutID"
      />
      <Table.Column
        title="Started"
        dataIndex="timeStarted"
        key="timeStarted"
        render={(timeStarted: string) => {
          const date = new Date(timeStarted)
          if (date.getUTCFullYear() !== 1) {
            return date.toLocaleString()
          } else {
            return "N/A"
          }
        }}
        width={200}
      />
    </Table>
  )
}
