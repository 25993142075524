import * as Sentry from "@sentry/react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { DraggableModalProvider } from "ant-design-draggable-modal"
import { message } from "antd"
import { default as Axios, default as axios } from "axios"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { AuthContextProvider } from "./context/AuthContext"
import "./index.css"
import { useAuthStore } from "./stores/AuthStore"
import { HttpStatusType } from "./types/enums/httpStatusEnums"
import { getEnvUrl } from "./utils/utils"
import { jwtVerify } from "jose"

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://admin.vizzn-dev.ca"],
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
    },
  },
})

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    let retryCount = 0
    const maxRetries = 2
    const originalRequest = error.config
    const env = useAuthStore.getState().env

    if (
      error.response?.data?.type === HttpStatusType.TOKEN_EXPIRED &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true

      const ogToken = useAuthStore.getState().token
      const user = useAuthStore.getState().user
      const authId = user[env]?.authId
      const userId = user[env]?.id
      const token = ogToken[env]

      if (!token) throw error
      try {
        await jwtVerify(
          token,
          new TextEncoder().encode(process.env.REACT_APP_JWT_SECRET)
        )
        return axios(originalRequest)
      } catch (error: any) {
        if (
          error?.code === "ERR_JWT_INVALID" ||
          error?.code === "ERR_JWS_SIGNATURE_VERIFICATION_FAILED"
        ) {
          await useAuthStore.getState().logout(env)
          message.error("Your session has expired. Please login again.")
          throw error
        }

        if (error?.code === "ERR_JWT_EXPIRED") {
          while (retryCount < maxRetries) {
            try {
              const { data } = await axios.post(
                `${getEnvUrl(env)}/workos/refresh-v2`,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${ogToken[env]}`,
                    "vizzn-auth-id": authId || "",
                    "vizzn-user-id": userId || "",
                  },
                }
              )

              useAuthStore.getState().setToken({
                ...ogToken,
                [env]: data.data,
              })
              originalRequest.headers.Authorization = `Bearer ${data.data}`

              return axios(originalRequest)
            } catch (error) {
              retryCount++
              if (retryCount === maxRetries) {
                await useAuthStore.getState().logout(env)
                message.error(
                  "Unable to refresh your session. Please login again."
                )
                throw error
              }
            }
          }
        }
        throw error
      }
    }

    if (error.response && typeof error.response.data.detail === "string") {
      if (error.response.data.detail === "Auth: Invalid auth credentials") {
        await useAuthStore.getState().logout(env)
        message.error("Your session has expired. Please login again.")
      } else {
        message.error(error.response.data.detail)
      }
    } else {
      message.error(error.response.data.detail)
    }
    return Promise.reject(error)
  }
)

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
message.success("app started")

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <DraggableModalProvider>
          <App />
        </DraggableModalProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
