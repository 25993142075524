import { Button, Card, message, Popconfirm, Row, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"

import { useCallback, useMemo, useState } from "react"
import { useSetEnterpriseAdmin } from "../../../apis/Enterprise"
import GenericTableComponent from "../../../components/GenericTableComponent/GenericTableComponent"
import { useAuthContext } from "../../../context/AuthContext"
import { EnterpriseAdmin } from "../../../types/Enterprise"
import AddAdminModal from "./AddAdminModal"
interface EnterpriseAdminListProps {
  admins: EnterpriseAdmin[]
  enterpriseId: string
}

export default function EnterpriseAdminList({
  admins,
  enterpriseId,
}: EnterpriseAdminListProps) {
  const { env, token } = useAuthContext()

  const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false)
  const { mutateAsync: setEnterpriseAdmin, isLoading } =
    useSetEnterpriseAdmin(enterpriseId)

  const currentAdminIds = admins.map((admin) => admin.authId)

  const handleRemoveAdmin = useCallback(
    async (userId: string) => {
      try {
        await setEnterpriseAdmin({
          env,
          token: token[env],
          userId,
          enterpriseId,
          remove: true,
        })
      } catch (error) {
        message.error(
          "There was an error removing the admin. Please try again."
        )
      }
    },
    [setEnterpriseAdmin, env, token, enterpriseId]
  )

  const columns: ColumnsType<EnterpriseAdmin> = useMemo(
    () => [
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Auth ID</Typography.Text>
          </Row>
        ),
        dataIndex: "authId",
        key: "authId",
        width: 130,
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Name</Typography.Text>
          </Row>
        ),
        dataIndex: "name",
        key: "name",
        render: (_, record) => `${record.firstName} ${record.lastName}`,
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Email</Typography.Text>
          </Row>
        ),
        dataIndex: "email",
        key: "email",
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Action</Typography.Text>
          </Row>
        ),
        key: "action",
        render: (_, record) => (
          <Row justify="center">
            <Popconfirm
              title={`Are you sure you want to remove ${record.firstName} ${record.lastName} as an admin?`}
              onConfirm={() => handleRemoveAdmin(record.authId)}
            >
              <Button type="primary" danger disabled={isLoading}>
                Remove
              </Button>
            </Popconfirm>
          </Row>
        ),
      },
    ],
    [handleRemoveAdmin, isLoading]
  )

  return (
    <>
      <Card
        title="Admins"
        extra={
          <Button type="primary" onClick={() => setIsAddAdminModalOpen(true)}>
            Add Admin
          </Button>
        }
      >
        <GenericTableComponent<EnterpriseAdmin>
          data={admins}
          columns={columns}
          searchFilter={["authId", "firstName", "lastName", "email"]}
        />
      </Card>
      {isAddAdminModalOpen && (
        <AddAdminModal
          isOpen={isAddAdminModalOpen}
          onClose={() => setIsAddAdminModalOpen(false)}
          enterpriseId={enterpriseId}
          ids={currentAdminIds}
        />
      )}
    </>
  )
}
