import { create } from "zustand"
import { persist } from "zustand/middleware"
import { environmentType } from "../types/environtment"
import { TokenState, UserState } from "../types/user"
import axios from "axios"
import { getEnvUrl } from "../utils/utils"

interface AuthStore {
  theme: "dark" | "light"
  setTheme: (theme: "dark" | "light") => void
  env: environmentType
  setEnv: (env: environmentType) => void
  token: TokenState
  setToken: (token: TokenState) => void
  user: UserState
  setUser: (user: UserState) => void
  clearData: (env: environmentType) => void
  logout: (env: environmentType) => Promise<void>
  isAuthenticated: { dev: boolean; prod: boolean }
  updateAuthStatus: () => void
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      theme: "dark",
      setTheme: (theme) => set({ theme }),
      env: "dev",
      setEnv: (env) => set({ env }),
      token: {
        dev: null,
        prod: null,
      },
      setToken: (token) => {
        set({ token })
        get().updateAuthStatus()
      },
      user: {
        dev: null,
        prod: null,
      },
      setUser: (user) => {
        set({ user })
        get().updateAuthStatus()
      },
      clearData: (env) => {
        set({
          user: {
            ...get().user,
            [env]: null,
          },
          token: {
            ...get().token,
            [env]: null,
          },
        })
        get().updateAuthStatus()
      },
      logout: async (env) => {
        const token = get().token[env]
        if (token) {
          try {
            // Always try to end WorkOS session first
            await axios.get(`${getEnvUrl(env)}/workos/logout`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
          } catch (error) {
            // Continue with local logout even if WorkOS logout fails
            get().clearData(env)
          }
        }
        get().clearData(env)
      },
      isAuthenticated: { dev: false, prod: false },
      updateAuthStatus: () => {
        const { user, token } = get()
        set({
          isAuthenticated: {
            dev:
              user?.dev !== null &&
              user?.dev !== undefined &&
              token?.dev !== null &&
              token?.dev !== undefined,
            prod:
              user?.prod !== null &&
              user?.prod !== undefined &&
              token?.prod !== null &&
              token?.prod !== undefined,
          },
        })
      },
    }),
    {
      name: "auth-storage",
    }
  )
)
