import { Switch } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons"
import { useAuthStore } from "../../stores/AuthStore"
import { useEffect, useState } from "react"

export const ThemeSwitcher = () => {
  const { theme, setTheme } = useAuthStore()
  const [isSystemDark, setIsSystemDark] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  )
  // Update the state when the system's color scheme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
    const changeHandler = () => setIsSystemDark(mediaQuery.matches)
    mediaQuery.addEventListener("change", changeHandler)
    return () => mediaQuery.removeEventListener("change", changeHandler)
  }, [])

  // Update the theme when the system's color scheme changes
  useEffect(() => {
    setTheme(isSystemDark ? "dark" : "light")
  }, [isSystemDark, setTheme])

  return (
    <Switch
      checkedChildren={<FontAwesomeIcon icon={faSun} />}
      unCheckedChildren={<FontAwesomeIcon icon={faMoon} />}
      checked={theme === "light"}
      onChange={(value) => setTheme(value ? "light" : "dark")}
    />
  )
}
