import React, { useEffect, useState } from "react"
import { SearchOutlined } from "@ant-design/icons"
import { Checkbox, Collapse, Input, Skeleton, Tag } from "antd"
import { uniqBy, without } from "lodash"

import "./SubscribablesList.scss"
import {
  useCreatUserSubscriptionV2,
  useDeleteSubscriptionV2,
  useMySubscriptionsDataV2,
  useSubscribablesV2,
} from "../../../apis/subscriptions"
import { useSearchTags } from "../../../utils/useSearchTags"
import { SubscribablesGroupV2 } from "../../../types/Subscription"
import { DisplayData, TokenState } from "../../../types/user"
import { GetError } from "../../../widgets/GetError"
import {
  getFilteredDataV2,
  getSubscribablesByGroupV2,
  getSubscribableStateV2,
  getSubscribedByGroupV2,
} from "../../../utils/utils"
import { environmentType } from "../../../types/environtment"

type Props = {
  userId: string
  env: environmentType
  token: TokenState
}

export const SubscribablesListV2 = ({ userId, env, token }: Props) => {
  /*   const { t } = useTranslation() */
  const { tags, addTag, removeTag, searchValue, setSearchValue } =
    useSearchTags("subscribables")
  /*  const { user: userData, env, token } = useAuthContext()
  const user = userData[env]! */

  const subscribables = useSubscribablesV2({ env, token: token[env] })

  const userSubscriptions = useMySubscriptionsDataV2({
    userId: userId,
    env,
    token: token[env],
  })
  const { mutateAsync: createUserSubscription } =
    useCreatUserSubscriptionV2(userId)
  const { mutateAsync: deleteUserSubscription } =
    useDeleteSubscriptionV2(userId)
  const [isLoading, setIsLoading] = useState(false)

  const [filteredData, setFilteredData] = useState<SubscribablesGroupV2[]>([])
  const [subscribablesByGroup, setSubscribablesByGroup] = useState<
    Record<string, DisplayData[]> | undefined
  >()
  const [subscribedByGroup, setSubscribedByGroup] = useState<
    Record<string, string[]> | undefined
  >()
  const [subscribableStates, setSubscribableStates] = useState<
    Record<string, boolean> | undefined
  >()

  // create an index of the subscribables state
  useEffect(() => {
    if (subscribableStates) return
    const userSubscriptionsIds = userSubscriptions.data?.map(
      ({ entity }) => entity.id
    )
    setSubscribableStates(
      userSubscriptionsIds ? getSubscribableStateV2(userSubscriptionsIds) : {}
    )
  }, [userSubscriptions.data, userId, subscribableStates])

  // create an index, with group name as key, that references all its subscribables (same data different structure)
  useEffect(() => {
    if (!subscribables.data || subscribablesByGroup) return
    setSubscribablesByGroup(getSubscribablesByGroupV2(subscribables.data))
  }, [subscribables.data, subscribablesByGroup])

  // create an index, with group name as key, that references an array of all the currently selected values
  useEffect(() => {
    if (!subscribables.data) return
    setSubscribedByGroup(
      getSubscribedByGroupV2(subscribables.data, subscribableStates || {})
    )
  }, [subscribableStates, subscribables.data])

  // create a filtered copy of the data to enable users to search
  useEffect(() => {
    if (!subscribables.data) return
    setFilteredData(getFilteredDataV2(subscribables.data, tags, searchValue))
  }, [searchValue, subscribables.data, tags])

  if (
    subscribables.isLoading ||
    userSubscriptions.isLoading ||
    !subscribedByGroup ||
    !subscribablesByGroup
  )
    return <Skeleton title paragraph={{ rows: 10 }} active />
  if (subscribables.error || !subscribables.data)
    return <GetError reFetch={subscribables.refetch} />
  if (userSubscriptions.error || !userSubscriptions.data)
    return <GetError reFetch={userSubscriptions.refetch} />

  const setMultipleSubscibalesStates = (
    subscribedIds: string[],
    newValue: boolean
  ) =>
    setSubscribableStates((state) => {
      return {
        ...state,
        ...subscribedIds.reduce((updatedSubscribed, subscribeId) => {
          updatedSubscribed[subscribeId] = newValue
          return updatedSubscribed
        }, {} as Record<string, boolean>),
      }
    })

  const updateSubscriptions = async (
    name: string,
    type: string,
    selectedValues: string[]
  ) => {
    setIsLoading(true)
    const currentValues = subscribedByGroup[name]
    const subscribedIds = without(selectedValues, ...currentValues)
    setMultipleSubscibalesStates(subscribedIds, true)
    if (subscribedIds.length) {
      const success = await createUserSubscription({
        userId: userId,
        entityId: subscribedIds[0],
        entityType: type,
        notiBucketIds: [],
        feedBucketIds: [],
        isDefault: true,
        env,
        token: token[env],
      })
      if (!success) {
        setMultipleSubscibalesStates(subscribedIds, false)
      }
    }

    const removedSubscriptions = without(currentValues, ...selectedValues)
    setMultipleSubscibalesStates(removedSubscriptions, false)
    if (removedSubscriptions.length) {
      const success = await deleteUserSubscription({
        subscriptionId: `${userId}_${removedSubscriptions[0]}`,
        id: userId,
        env,
        token: token[env],
      })
      if (!success) {
        setMultipleSubscibalesStates(removedSubscriptions, true)
      }
    }
    setIsLoading(false)
  }

  return (
    <div className="SubscribablesList">
      <Input
        className="list-search-input"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={"Search"}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            addTag(searchValue)
            setSearchValue("")
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Backspace" && !searchValue && tags.length) {
            removeTag(tags[tags.length - 1])
          }
        }}
        addonBefore={
          tags
            ? tags.map((name) => (
                <Tag
                  key={`sorting-tag-${name}`}
                  className="list-tag-filter"
                  color="grey"
                  closable
                  onClose={() => removeTag(name)}
                >
                  {name}
                </Tag>
              ))
            : null
        }
        addonAfter={<SearchOutlined />}
      />
      <Collapse className="subscribables-list">
        {filteredData.map(({ name, type, entities }) => (
          <Collapse.Panel
            header={
              <>
                {name} (
                {
                  entities.filter(({ id }) =>
                    subscribedByGroup[name]?.includes(id)
                  ).length
                }{" "}
                / {entities.length})
              </>
            }
            key={name}
          >
            <Checkbox.Group
              disabled={isLoading}
              options={uniqBy(
                entities
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .sort((a, b) => {
                    if (subscribedByGroup[name]?.includes(b.id)) return 1
                    if (subscribedByGroup[name]?.includes(a.id)) return -1
                    return 0
                  })
                  .map(({ name, id }) => ({ label: name, value: id })),
                "value"
              )}
              value={subscribedByGroup[name]}
              onChange={async (selectedCheckValues) => {
                const currentValues = subscribedByGroup[name] || []
                const displayedSubscribableIds = entities.map(({ id }) => id)
                const nextValues = [
                  ...currentValues.filter(
                    (id) => !displayedSubscribableIds.includes(id)
                  ),
                  ...(selectedCheckValues as string[]),
                ]
                updateSubscriptions(name, type, nextValues)
              }}
            ></Checkbox.Group>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  )
}
