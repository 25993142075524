import { Button, Col, Form, message, Modal, Row, Select, Skeleton } from "antd"
import { useSetEnterpriseTeam } from "../../../apis/Enterprise"
import { useGetTeamsNotArchived } from "../../../apis/Enterprise/getTeams"
import ErrorRefetch from "../../../components/ErrorRefetch/ErrorRefetch"
import { useAuthContext } from "../../../context/AuthContext"

interface AddChildModalProps {
  open: boolean
  onClose: () => void
  enterpriseId: string
  ids: string[]
}

export default function AddChildModal({
  open,
  onClose,
  enterpriseId,
  ids,
}: AddChildModalProps) {
  const [form] = Form.useForm()

  const { env, token } = useAuthContext()

  const teams = useGetTeamsNotArchived()
  const { mutateAsync: setEnterpriseTeam, isLoading } =
    useSetEnterpriseTeam(enterpriseId)

  const handleSubmit = async (values: { teamId: string }) => {
    try {
      await setEnterpriseTeam({
        env,
        token: token[env],
        teamId: values.teamId,
        enterpriseId,
      })

      onClose()
    } catch (error) {
      message.error(
        "There was an error adding the child team. Please try again."
      )
    }
  }

  return (
    <Modal open={open} onCancel={onClose} title="Add Child Team" footer={null}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "2rem",
          paddingBottom: "2rem",
        }}
      >
        {teams.isLoading && <Skeleton />}
        {teams.isError && <ErrorRefetch refetch={teams.refetch} />}
        {teams.data && (
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item name="teamId" label="Team" rules={[{ required: true }]}>
              <Select
                showSearch
                placeholder="Select a team"
                options={teams.data
                  .filter((team) => !ids.includes(team.id))
                  .map((team) => ({
                    label: team.name,
                    value: team.id,
                  }))}
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Row justify="end" gutter={16}>
              <Col>
                <Button disabled={isLoading} onClick={onClose}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button disabled={isLoading} type="primary" htmlType="submit">
                  Add Admin Team
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  )
}
