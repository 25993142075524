import React, { useState, useEffect, useCallback } from "react"
import { Modal } from "antd"

interface KonamiCodeProps {
  onComplete: () => void
  children?: React.ReactNode
}

const KONAMI_CODE: readonly string[] = [
  "ArrowUp",
  "ArrowUp",
  "ArrowDown",
  "ArrowDown",
  "ArrowLeft",
  "ArrowRight",
  "ArrowLeft",
  "ArrowRight",
  "b",
  "a",
] as const

export const KonamiCode = ({
  onComplete,
  children,
}: KonamiCodeProps): React.ReactElement | null => {
  const [, setSequence] = useState<string[]>([])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      const key = event.key

      setSequence((prevSequence) => {
        const newSequence = [...prevSequence, key]

        // Only keep the last N keys where N is the length of the konami code
        if (newSequence.length > KONAMI_CODE.length) {
          newSequence.shift()
        }

        // Check if the sequence matches the Konami code
        const isKonamiCode =
          newSequence.length === KONAMI_CODE.length &&
          newSequence.every((key, index) => {
            // Handle potential undefined values and ensure case-insensitive comparison
            const inputKey = key?.toLowerCase() || ""
            const konamiKey = KONAMI_CODE[index].toLowerCase()
            return inputKey === konamiKey
          })

        if (isKonamiCode) {
          onComplete()
        }

        return newSequence
      })
    },
    [onComplete]
  )

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleKeyDown])

  return React.isValidElement(children) ? children : null
}

const EasterEggComponent: React.FC = () => {
  const [cheatActivated, setCheatActivated] = useState<boolean>(false)

  const handleKonamiCode = (): void => {
    setCheatActivated(true)
    return
  }

  return (
    <KonamiCode onComplete={handleKonamiCode}>
      <Modal
        open={cheatActivated}
        onCancel={() => setCheatActivated(false)}
        footer={null}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "2rem" }}>🎮 Cheat Code Activated! 🎮</span>
          <span>Easter Egg added by Forest Park 🌲. Jan 17, 2025</span>
        </div>
      </Modal>
    </KonamiCode>
  )
}

export default EasterEggComponent
