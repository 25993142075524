import { Select } from "antd"
import { useAuthStore } from "../../stores/AuthStore"
import { useState } from "react"
import ForceSetToken from "./ForceSetToken"
import { environmentType } from "../../types/environtment"

export default function DebugSelectMenu() {
  const { isAuthenticated, clearData, user, token, setToken } = useAuthStore()
  const [open, setOpen] = useState(false)
  const [selectValue, setSelectValue] = useState<string>()

  const clearEnvToken = (env: environmentType) => {
    setToken({
      ...token,
      [env]: null,
    })
  }

  const handleSelect = (value: string) => {
    setSelectValue(value)
    if (value === "clearDev") clearData("dev")
    if (value === "clearProd") clearData("prod")
    if (value === "clearDevToken") clearEnvToken("dev")
    if (value === "clearProdToken") clearEnvToken("prod")
    if (value === "user") console.log("🤷‍♂️🤷‍♂️🤷‍♂️ ", user)
    if (value === "token") console.log("🪙🪙🪙 ", token)
    if (value === "isAuthenticated") console.log("🔑🔑🔑 ", isAuthenticated)
    if (value === "forceSetToken") setOpen(true)
  }

  return (
    <>
      <Select
        placeholder="Debug Menu"
        style={{ width: 180, marginRight: "16px" }}
        onChange={handleSelect}
        value={selectValue}
      >
        <Select.Option value="clearDev">Clear Dev</Select.Option>
        <Select.Option value="clearProd">Clear Prod</Select.Option>
        <Select.Option value="clearDevToken">Clear Dev Token</Select.Option>
        <Select.Option value="clearProdToken">Clear Prod Token</Select.Option>
        <Select.Option value="user">Log User</Select.Option>
        <Select.Option value="token">Log Token</Select.Option>
        <Select.Option value="isAuthenticated">Log Auth</Select.Option>
        <Select.Option value="forceSetToken">Force Set Token</Select.Option>
      </Select>
      <ForceSetToken
        open={open}
        setOpen={(isOpen) => {
          setOpen(isOpen)
          if (!isOpen) setSelectValue(undefined)
        }}
      />
    </>
  )
}
