import { useState, useEffect } from "react"

export function useLocalStorage<T>(
  key: string,
  initialValue?: T
): [T, (value: T | ((prev: T) => T)) => void] {
  // Get initial value from localStorage if exists, otherwise use initialValue
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error)
      return initialValue
    }
  })

  // Update localStorage whenever state changes
  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (error) {
      console.error(`Error writing to localStorage key "${key}":`, error)
    }
  }, [key, storedValue])

  // Wrapper around setState that updates both state and localStorage
  const setValue = (value: T | ((prev: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
    } catch (error) {
      console.error(`Error setting value for localStorage key "${key}":`, error)
    }
  }

  return [storedValue, setValue]
}
