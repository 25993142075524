import { Button, Card, Form, Input, message, Select, Typography } from "antd"
import { motion } from "framer-motion"
import { useEffect, useState, useRef } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import { useAuthStore } from "../stores/AuthStore"
import { environmentType } from "../types/environtment"
import EasterEggComponent from "../components/EasterEgg"

export const Login = () => {
  const { handleSetEnv, redirect, handleWorkosLogin, setToken, setUser } =
    useAuthContext()
  const { isAuthenticated, env, clearData } = useAuthStore()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const hasCleared = useRef(false)

  const [form] = Form.useForm()

  useEffect(() => {
    // Clear auth data when landing on login page and not authenticated
    if (!isAuthenticated[env] && !hasCleared.current) {
      clearData(env)
      hasCleared.current = true
    }

    // Reset the flag if the user becomes authenticated
    if (isAuthenticated[env]) {
      hasCleared.current = false
    }
  }, [env, isAuthenticated, clearData])

  const handleLoginMethod = async (values: any) => {
    setLoading(true)

    try {
      const res: any = await handleWorkosLogin({
        email: values.email,
        password: values.password,
        navigate: () => navigate(redirect || "/"),
      })

      if (!res.user || !res.token) {
        setLoading(false)
        return
      }

      setUser(res.user)
      setToken(res.token)
      setLoading(false)
    } catch (error) {
      console.log(error)
      message.error("There was an error logging in. Please try again.")
      return
    } finally {
      setLoading(false)
      return
    }
  }

  useEffect(() => {
    form.setFieldValue("env", env)
  }, [env, form])

  if (isAuthenticated[env]) {
    return <Navigate to="/" replace />
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          paddingBottom: "2rem",
        }}
      >
        <EasterEggComponent />
        <Typography.Title level={2}>Welcome to Vizzn Admin</Typography.Title>
        <Typography.Text>Please login to continue</Typography.Text>
      </div>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <Card>
          <Form form={form} onFinish={handleLoginMethod}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input your email" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password" },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Select Environment"
              name="env"
              initialValue={env || "dev"}
            >
              <Select
                onChange={(value: environmentType) => handleSetEnv(value)}
              >
                <Select.Option value="dev">Development</Select.Option>
                <Select.Option value="prod">Production</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                loading={loading}
                disabled={loading}
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </motion.div>
    </div>
  )
}
