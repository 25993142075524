import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import axios from "axios"
import { environmentType } from "../types/environtment"
import {
  AuthUserCreateRequestDto,
  UserEmailRequest,
  UserNotification,
  UserNotificationPreference,
  UserNotificationRequest,
  UserResponseV2,
  VizznUser,
  WorkHistoryRequest,
  WorkHistoryResponse,
} from "../types/user"
import { getEnvUrl } from "../utils/utils"
import { useState } from "react"
import { UserLocation } from "../types/Location"
import { Device } from "../types/Device"
import { VizznResponse } from "../types/AccountApplication"
import { message } from "antd"
import { LanguageCode } from "../types/Language"
import { useAuthContext } from "../context/AuthContext"
import { useNavigate } from "react-router-dom"

type PostResponse = VizznResponse<{ id: string }>

export const getUserLocations = async (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  if (!body.userId || !body.token) return
  const { data } = await axios.get(`${getEnvUrl(body.env)}/v1/user-locations`, {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    params: {
      userId: body.userId,
    },
  })
  if (data) return data.records
}

export const useGetUserLocations = (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  const { userId, env, token } = body
  const response = useQuery<UserLocation[]>(
    ["locations", body.userId, body.env],
    () => getUserLocations({ userId, env, token })
  )

  return response
}
export const getUserDevices = async (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  if (!body.userId || !body.token) return
  const { data } = await axios.get(`${getEnvUrl(body.env)}/v1/user-devices`, {
    headers: {
      Authorization: `Bearer ${body.token}`,
    },
    params: {
      userId: body.userId,
    },
  })
  if (data) return data.records
}

export const useGetUserDevices = (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  const { userId, env, token } = body
  const response = useQuery<Device[]>(["devices", body.userId, body.env], () =>
    getUserDevices({ userId, env, token })
  )

  return response
}

export const getUserData = async (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  if (!body.userId || !body.token) return
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/users/${body.userId}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data.data
}

export const initializeAuthUserData = async (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  if (!body.userId || !body.token) return
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/user-accounts/${body.userId}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data.data
}

export const useGetUserData = (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  const { userId, env, token } = body
  const response = useQuery<VizznUser>(["user", body.userId, body.env], () =>
    getUserData({ userId, env, token })
  )

  return response
}

export const updateUserLanguagePreference = async (body: {
  payload: {
    id: string
    languagePreference: LanguageCode
  }
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.patch(
      `${getEnvUrl(body.env)}/v1/users/${body.payload.id}`,
      body.payload,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useUpdateUserLanguagePreference = (
  id: string,
  env: environmentType
) => {
  const queryClient = useQueryClient()
  return useMutation(updateUserLanguagePreference, {
    onSuccess: () => {
      message.success("Language Preference updated")
      queryClient.invalidateQueries(["user", id, env])
    },
  })
}

const updateUserFocusModeOnly = async (body: {
  payload: {
    id: string
    focusModeOnly: boolean
  }
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.patch(
      `${getEnvUrl(body.env)}/v1/users/${body.payload.id}`,
      body.payload,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useUpdateUserFocusModeOnly = (
  id: string,
  env: environmentType
) => {
  const queryClient = useQueryClient()
  return useMutation(updateUserFocusModeOnly, {
    onSuccess: () => {
      message.success("Focus Mode Only updated")
      queryClient.invalidateQueries(["user", id, env])
    },
  })
}

export const debouncedUsersFetch = async (body: {
  env: environmentType
  token: string | null
  config: {
    searchProp: "email" | "id" | "name" | "teamId"
    searchTerm: string
    page: number
    size: number
  }
}) => {
  if (!body.token) return
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/user-service/search`,
    body.config,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) return data
}

export const useGetUsersV2 = (
  body: {
    env: environmentType
    token: string | null
  },
  searchTerm: string
) => {
  const fetchUsers = async (page: number = 1) => {
    if (!body.token || searchTerm.length < 3) return
    const { data } = await axios.get(`${getEnvUrl(body.env)}/v2/users/search`, {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
      params: { searchTerm },
    })
    if (data) return data.data
  }

  const users = useQuery<UserResponseV2>(
    ["user-listV2", body.env, searchTerm],
    (param) => fetchUsers(param.pageParam),
    {
      enabled: !!searchTerm,
    }
  )

  return users
}

export const usePaginatedUserNotification = (body: {
  id?: string
  pageSize: number
  env: environmentType
  token: string | null
  start?: Date
  end?: Date
}) => {
  const [page, setPage] = useState(1)

  const fetchPage = async ({ pageParam = 1 }) => {
    const { data } = await axios.get(
      `${getEnvUrl(body.env)}/v1/user-notifications`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        params: {
          userId: body.id,
          page: pageParam,
          size: body.pageSize || 20,
          start: body.start,
          end: body.end,
        },
      }
    )

    setPage((prev) => prev + 1)

    return data.records
  }
  const notifications = useInfiniteQuery<UserNotification[]>({
    queryKey: ["activities", body.id, body.env],
    queryFn: fetchPage,
    getNextPageParam: (latestPage, allPages) => {
      return latestPage.length < body.pageSize ? undefined : page
    },
  })

  return notifications
}
const changeUserTeam = async (body: {
  userId: string
  teamId: string
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.put<VizznResponse<{ teamId: string }>>(
      `${getEnvUrl(body.env)}/v1/users/${body.userId}/teams/${body.teamId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(data.detail)
    return data.data.teamId
  } catch (error) {
    // axios response error intercepted
  }
}

export const useChangeUserTeam = (id: string, env: environmentType) => {
  const queryClient = useQueryClient()

  return useMutation(changeUserTeam, {
    onSuccess: async (newTeamId) => {
      if (!newTeamId) return
      const oldData = queryClient.getQueryData<VizznUser>(["user", id, env])
      if (oldData) {
        queryClient.setQueryData<VizznUser>(["user", id, env], () => oldData)
      }
      queryClient.refetchQueries()
    },
  })
}

export const sendTestNotification = async (body: {
  userId?: string
  env: environmentType
  token: string | null
}) => {
  if (!body.userId || !body.token) return
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/users/${
      body.userId
    }/notification-preferences/test`,
    undefined,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (!data) return
  return data
}

const getUserCache = async (body: {
  env: environmentType
  userId?: string
  token: string | null
  userCtxId?: string
}) => {
  if (!body.userId || !body.token || !body.userCtxId) return
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/user-cache/${body.userId}`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}

export const useGetUserCache = (body: {
  userId?: string
  env: environmentType
  token: string | null
  userCtxId?: string
}) => {
  const { userId, env, token, userCtxId } = body
  const response = useQuery(["user-cache", body.userId, body.env], () =>
    getUserCache({ userId, env, token, userCtxId })
  )

  return response
}

export const updateUserNotificationPreferences = async (body: {
  id: string
  env: environmentType
  token: string | null
  payload: UserNotificationRequest
}) => {
  if (!body.token) return
  try {
    const { data } = await axios.put<
      PostResponse & { data: UserNotificationPreference }
    >(
      `${getEnvUrl(body.env)}/v1/users/${body.id}/notification-preferences`,
      body.payload,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(data.detail)
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useUpdateUserNotificationPreferences = (
  id: string,
  env: environmentType
) => {
  const queryClient = useQueryClient()
  return useMutation(updateUserNotificationPreferences, {
    onSuccess: async (pref) => {
      if (!pref) return
      const oldData = queryClient.getQueryData<VizznUser>(["user", id, env])
      if (oldData) {
        oldData.notificationPreference = pref
        queryClient.setQueryData<VizznUser>(["user", id, env], () => oldData)
      }
    },
  })
}

export const getWorkHistory = async (body: {
  env: environmentType
  token: string | null
  payload: WorkHistoryRequest
}) => {
  if (!body.token) return
  const { data } = await axios.get<VizznResponse<WorkHistoryResponse>>(
    `${getEnvUrl(body.env)}/v1/user-work-history`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
      params: body.payload,
    }
  )

  return data.data
}
export const useGetWorkHistory = (body: {
  env: environmentType
  token: string | null
  payload: WorkHistoryRequest
}) => {
  const response = useQuery<WorkHistoryResponse | undefined>(
    ["work-history", body],
    () => getWorkHistory(body),
    { enabled: !!body.payload.teamId }
  )

  return response
}

const patchUserData = async (body: {
  env: environmentType
  token: string | null
  payload: Partial<VizznUser>
}) => {
  if (!body.token) return
  const { data } = await axios.patch<VizznResponse<{ id: string }>>(
    `${getEnvUrl(body.env)}/v1/users/${body.payload.id}`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data.data
}

export function usePatchUserData(env: environmentType) {
  const queryClient = useQueryClient()
  return useMutation(patchUserData, {
    onSuccess: async (data) => {
      if (!data) return
      queryClient.invalidateQueries(["user", data.id, env])
      queryClient.invalidateQueries(["user-accounts", env])
    },
  })
}

export const updateUser = async (body: {
  id: string
  fileAccessLevel?: number
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await axios.patch(
      `${getEnvUrl(body.env)}/v1/users/${body.id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useUpdateUser = (id: string, env: environmentType) => {
  const queryClient = useQueryClient()
  return useMutation(updateUser, {
    onSuccess: () => {
      message.success("User updated")
      queryClient.invalidateQueries(["user", id, env])
    },
  })
}

export const updateUserEmail = async (
  body: UserEmailRequest,
  env: environmentType,
  token: string | null
) => {
  try {
    const { data } = await axios.patch<VizznResponse<VizznUser>>(
      `${getEnvUrl(env)}/v1/users/${body.id}/email`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    message.success(data.detail)
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useUpdateUserEmail = (
  id: string,
  env: environmentType,
  token: string | null
) => {
  const navigate = useNavigate()
  const { user, handleWorkosLogout } = useAuthContext()
  const queryClient = useQueryClient()
  return useMutation(
    (body: UserEmailRequest) => updateUserEmail(body, env, token),
    {
      onSuccess: async (response) => {
        if (user[env]?.uid === response?.authId) {
          handleWorkosLogout({ navigate: () => navigate("/login") })
          return
        }

        if (!response) return

        const oldData = queryClient.getQueryData<VizznUser>(["user", id, env])
        if (oldData) {
          oldData.email = response.email
          queryClient.setQueryData<VizznUser>(["user", id, env], () => oldData)
        }
      },
    }
  )
}

export const updateUserStatus = async (
  userId: string,
  activeAccount: boolean,
  env: environmentType,
  token: string | null
) => {
  const { data } = await axios.patch<VizznResponse<VizznUser>>(
    `${getEnvUrl(env)}/v1/users/${userId}/account`,
    { activate: !activeAccount },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  return data
}

export const useUpdateUserStatus = (
  id: string,
  env: environmentType,
  token: string | null
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (activeAccount: boolean) => updateUserStatus(id, activeAccount, env, token),
    {
      onSuccess: (_, activeAccount) => {
        const oldData = queryClient.getQueryData<VizznUser>(["user", id, env])
        if (oldData) {
          oldData.activeAccount = !activeAccount
          queryClient.setQueryData<VizznUser>(["user", id, env], { ...oldData })
        }
        message.success(
          `User ${!activeAccount ? "activated" : "deactivated"} successfully`
        )
      },
      onError: () => {
        message.error("Failed to update user status")
      },
    }
  )
}

// create user
const createUser = async (body: {
  env: environmentType
  token: string | null
  dto: AuthUserCreateRequestDto
}) => {
  const { data } = await axios.post<VizznResponse<{ id: string }>>(
    `${getEnvUrl(body.env)}/v1/users`,
    body.dto,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )

  return data.data
}

export const useCreateUser = (env: environmentType) => {
  const queryClient = useQueryClient()
  return useMutation(createUser, {
    onSuccess: async (data) => {
      if (!data) return
      queryClient.invalidateQueries(["user", data.id, env])
    },
  })
}

/**
 * async #createUser(
    dto: UserCreateRequestDto,
    teamId: string,
  ): Promise<UserDataDto> {
    if (dto.userAuthId) {
      return UserService.createUserOnly(this.ctx, {
        activateAccount: dto.activateAccount,
        authId: dto.userAuthId,
        email: dto.email,
        firstName: dto.firstName,
        focusModeOnly: dto.focusModeOnly,
        jobTitle: dto.jobTitle,
        languagePreference: dto.languagePreference,
        lastName: dto.lastName,
        phone: dto.phone,
        roles: [...dto.roles, ...dto.roleTags],
        teamId,
      })
    }

    return UserService.create(this.ctx, {
      activateAccount: dto.activateAccount,
      createMethod: 'teamAdmin', // TODO
      email: dto.email,
      firstName: dto.firstName,
      focusModeOnly: dto.focusModeOnly,
      jobTitle: dto.jobTitle,
      languagePreference: dto.languagePreference,
      lastName: dto.lastName,
      phone: dto.phone,
      roles: [...dto.roles, ...dto.roleTags],
      teamId,
    })
  }
 */

// const createUser = async (body: UserRequest): Promise<User | undefined> => {
//   try {
//     const { data } = await Axios.post<VizznResponse<User>>('/v1/users', body)
//     message.success(data.detail)
//     return data.data
//   } catch (error) {
//     // axios response error intercepted
//   }
// }

// export const useCreateUser = () => {
//   const queryClient = useQueryClient()

//   return useMutation(createUser, {
//     onSuccess: async (user) => {
//       if (!user) return
//       queryClient.setQueryData<User[]>(['users'], (results) => {
//         if (!results) return [user]
//         return [user, ...results].sort((a, b) => (a.name > b.name ? 1 : -1))
//       })
//     },
//   })
// }
