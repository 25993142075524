import { Button, Card, message, Popconfirm, Row, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useCallback, useMemo, useState } from "react"
import { useSetEnterpriseTeam } from "../../../apis/Enterprise"
import GenericTableComponent from "../../../components/GenericTableComponent/GenericTableComponent"
import { useAuthContext } from "../../../context/AuthContext"
import { EnterpriseChild } from "../../../types/Enterprise"
import AddChildModal from "./AddChildModal"

interface EnterpriseChildrenListProps {
  children: EnterpriseChild[]
  enterpriseId: string
}

export default function EnterpriseChildrenList({
  children,
  enterpriseId,
}: EnterpriseChildrenListProps) {
  const [isAddChildModalOpen, setIsAddChildModalOpen] = useState(false)

  const { env, token } = useAuthContext()

  const { mutateAsync: removeEnterpriseTeam, isLoading } =
    useSetEnterpriseTeam(enterpriseId)

  const currentChildIds = children.map((child) => child.id)

  const handleRemoveTeam = useCallback(
    async (teamId: string) => {
      try {
        await removeEnterpriseTeam({
          env,
          token: token[env],
          teamId,
          enterpriseId,
          remove: true,
        })
      } catch (error) {
        message.error("There was an error removing the team. Please try again.")
      }
    },
    [removeEnterpriseTeam, env, token, enterpriseId]
  )

  const columns: ColumnsType<EnterpriseChild> = useMemo(
    () => [
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>ID</Typography.Text>
          </Row>
        ),
        dataIndex: "id",
        key: "id",
        width: 130,
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Name</Typography.Text>
          </Row>
        ),
        dataIndex: "name",
        key: "name",
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>User Count</Typography.Text>
          </Row>
        ),
        dataIndex: "userCount",
        key: "userCount",
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Action</Typography.Text>
          </Row>
        ),
        key: "action",
        render: (_, record) => (
          <Row justify="center">
            <Popconfirm
              title="Are you sure you want to remove this team?"
              onConfirm={() => handleRemoveTeam(record.id)}
            >
              <Button type="primary" danger disabled={isLoading}>
                Remove
              </Button>
            </Popconfirm>
          </Row>
        ),
      },
    ],
    [handleRemoveTeam, isLoading]
  )

  return (
    <>
      <Card
        title="Organizations"
        extra={
          <Button type="primary" onClick={() => setIsAddChildModalOpen(true)}>
            Add Organization
          </Button>
        }
      >
        <GenericTableComponent<EnterpriseChild>
          data={children}
          columns={columns}
          searchFilter={["name", "id"]}
        />
      </Card>
      {isAddChildModalOpen && (
        <AddChildModal
          open={isAddChildModalOpen}
          onClose={() => setIsAddChildModalOpen(false)}
          enterpriseId={enterpriseId}
          ids={currentChildIds}
        />
      )}
    </>
  )
}
