import { initializeApp } from "firebase/app"
import { firebaseConfigDev, firebaseConfigProd } from "../firebase"
import { environmentType } from "../types/environtment"
import { DisplayData } from "../types/shared"
import { SubsribablesGroup } from "../types/List"
import {
  filterBySearchValueAndTag,
  isStringContainingSearchValues,
} from "../components/Utils/search"
import { SubscribablesGroupV2 } from "../types/Subscription"

const appDev = initializeApp(firebaseConfigDev, "development")
const appProd = initializeApp(firebaseConfigProd, "production")

export const getCurrentEnvironment = (env: environmentType) => {
  switch (env) {
    case "prod":
      return appProd

    default:
      return appDev
  }
}

export const getEnvUrl = (env: environmentType) => {
  switch (env) {
    case "prod":
      return "https://api.vizzn.ca"
    //return "http://localhost:8889"
    //return "https://api.vizzn.ca"

    default:
      return "https://api.vizzn-dev.ca"
    // return "http://localhost:8889"
    //return "https://api.vizzn-dev.ca";
  }
}
export const getAppUrl = (env: environmentType) => {
  switch (env) {
    case "prod":
      return "https://app.vizzn.ca"

    default:
      return "https://app.vizzn-dev.ca"
  }
}
export const isStateIndeterminate = (
  subscribables: DisplayData[],
  subscribedValues: string[]
) => {
  const values = {
    subscribables: subscribables || [],
    subscribedValues: subscribedValues || [],
  }
  return (
    values.subscribables.length !== values.subscribedValues.length &&
    values.subscribables.length !== 0
  )
}
export const isStateChecked = (
  subscribables: DisplayData[],
  subscribedValues: string[]
) => {
  const values = {
    subscribables: subscribables || [],
    subscribedValues: subscribedValues || [],
  }
  return values.subscribedValues.length === values.subscribables.length
}

// create an index, with group name as key, that references all its subscribables (came data differen structure)
export const getSubscribablesByGroup = (
  subscribablesGroups: SubsribablesGroup[]
) =>
  subscribablesGroups.reduce((state, [entityName, subscribables]) => {
    state[entityName] = subscribables || []
    return state
  }, {} as Record<string, DisplayData[]>)

export const getSubscribablesByGroupV2 = (
  subscribablesGroups: SubscribablesGroupV2[]
) =>
  subscribablesGroups.reduce((state, { name, entities }) => {
    state[name] = entities || []
    return state
  }, {} as Record<string, DisplayData[]>)

export const getSubscribableState = (userSubscriptionsIds: string[]) =>
  userSubscriptionsIds?.reduce((state, subscriptionId) => {
    state[subscriptionId] = true
    return state
  }, {} as Record<string, boolean>)

export const getSubscribableStateV2 = (userSubscriptionsIds: string[]) =>
  userSubscriptionsIds?.reduce((state, subscriptionId) => {
    state[subscriptionId] = true
    return state
  }, {} as Record<string, boolean>)

export const getSubscribedByGroup = (
  subscribablesGroups: SubsribablesGroup[],
  subscribableStates: Record<string, boolean>
) => {
  return subscribablesGroups.reduce((state, [entityName, subscribables]) => {
    const subscribedInGroup = subscribables
      .filter((subscribable) => subscribableStates[subscribable.id])
      .map(({ id }) => id)
    state[entityName] = subscribedInGroup
    return state
  }, {} as Record<string, string[]>)
}
export const getSubscribedByGroupV2 = (
  subscribablesGroups: SubscribablesGroupV2[],
  subscribableStates: Record<string, boolean>
) => {
  return subscribablesGroups.reduce((state, { name, entities }) => {
    const subscribedInGroup = entities
      .filter((subscribable) => subscribableStates[subscribable.id])
      .map(({ id }) => id)
    state[name] = subscribedInGroup
    return state
  }, {} as Record<string, string[]>)
}
// create a filtered copy of the data to enable users to search
export const getFilteredData = (
  subscribablesGroups: SubsribablesGroup[],
  tags: string[],
  searchValue: string
) => {
  const searchFields = ["name"]

  let filteredSubscribableGroups = [...subscribablesGroups]
  if (!tags.length && !searchValue) return filteredSubscribableGroups
  return filteredSubscribableGroups
    .map(([entityName, subscribables]) => {
      // if the entity name matches the search value, all values within that category should show
      // otherwise, only values within the category, that match the searchvalue
      const filteredSubscribables = isStringContainingSearchValues(
        entityName as string,
        tags,
        searchValue
      )
        ? subscribables
        : filterBySearchValueAndTag<DisplayData>({
            tags,
            searchValue,
            searchFields,
            dataArray: subscribables,
          })
      return [entityName, filteredSubscribables] as SubsribablesGroup
    })
    .filter(([, subscribables]) => subscribables.length)
}
export const getFilteredDataV2 = (
  subscribablesGroups: SubscribablesGroupV2[],
  tags: string[],
  searchValue: string
) => {
  const searchFields = ["name"]

  let filteredSubscribableGroups = [...subscribablesGroups]
  if (!tags.length && !searchValue) return filteredSubscribableGroups
  return filteredSubscribableGroups
    .map(({ name, entities }) => {
      // if the entity name matches the search value, all values within that category should show
      // otherwise, only values within the category, that match the searchvalue
      const filteredSubscribables = isStringContainingSearchValues(
        name as string,
        tags,
        searchValue
      )
        ? entities
        : filterBySearchValueAndTag<DisplayData>({
            tags,
            searchValue,
            searchFields,
            dataArray: entities,
          })
      return { name, entities: filteredSubscribables } as SubscribablesGroupV2
    })
    .filter(({ entities }) => entities.length)
}

export const entityType: EntityType[] = [
  { type: "classification", label: "Entity Classes" },
  { type: "combinationType", label: "Combination Type" },
  { type: "costCode", label: "Cost Code" },
  { type: "crew", label: "Crew" },
  { type: "crewActivity", label: "Crew Activity" },
  { type: "dispatchEventType", label: "Dispatch Event Type" },
  { type: "dispatchGroup", label: "Dispatcher Team" },
  { type: "dispatchRequestType", label: "Dispatch Request Type" },
  { type: "equipment", label: "Equipment" },
  { type: "equipmentAttachment", label: "Equipment Attachment" },
  { type: "externalCompany", label: "External Company" },
  { type: "externalPerson", label: "External Person" },
  { type: "externalSite", label: "External Site" },
  { type: "folder", label: "Folder" },
  { type: "form", label: "Form" },
  { type: "formSubmission", label: "Form Submission" },
  { type: "generalActivity", label: "General Activity" },
  { type: "site", label: "Site" },
  { type: "team", label: "Team" },
  { type: "trailer", label: "Trailer" },
  { type: "trailerCombination", label: "Trailer Combination" },
  { type: "truck", label: "Truck" },
  { type: "truckConfiguration", label: "Truck Configuration" },
  { type: "user", label: "User" },
]
export const entityTypeMapping: Record<string, string> = entityType.reduce(
  (obj, arr) => {
    obj[arr.type] = arr.label
    return obj
  },
  {} as Record<string, string>
)

export type EntityType = {
  type: string
  label: string
}
