import { message } from "antd"
import axios from "axios"
import { initializeApp } from "firebase/app"
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
} from "firebase/auth"
import { Firestore, getFirestore } from "firebase/firestore"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { useRefreshWorkosToken } from "../apis/auth/refreshWorkosToken"
import { initializeAuthUserData } from "../apis/user"
import { getUserAccountV2 } from "../apis/WorkOs/getUserAccountV2"
import { firebaseConfigDev, firebaseConfigProd } from "../firebase"
import { useAuthStore } from "../stores/AuthStore"
import { environmentType } from "../types/environtment"
import { AuthProviderState, TokenState, UserState } from "../types/user"
import { WorkosLoginResponse } from "../types/WorkOs/WorkosLoginResponse"
import { useLocalStorage } from "../utils/hooks/useLocalStorage"

import { getEnvUrl } from "../utils/utils"

const appDev = initializeApp(firebaseConfigDev, "development")
const appProd = initializeApp(firebaseConfigProd, "production")

type Props = {
  children: ReactNode
}

type Context = {
  token: TokenState
  setToken: (token: TokenState) => void
  env: environmentType
  error: string
  handleLogin: (
    email: string,
    password: string,
    navigate?: () => void
  ) => Promise<void>
  handleSetEnv: (envName: environmentType) => void
  user: UserState
  setUser: (user: UserState) => void
  redirect: string | null
  setRedirect: Dispatch<SetStateAction<string | null>>
  getDb: (env: environmentType) => Firestore
  isFirebaseLogin: AuthProviderState
  setIsFirebaseLogin: Dispatch<SetStateAction<AuthProviderState>>
  handleWorkosLogin: (body: {
    email: string
    password: string
    navigate?: () => void
  }) => Promise<any>
  handleWorkosLogout: (body: { navigate?: () => void }) => Promise<void>
}

const getCurrentEnvironment = (env: environmentType) => {
  switch (env) {
    case "prod":
      return appProd
    default:
      return appDev
  }
}

const getDb = (env: environmentType) => {
  const db = getFirestore(getCurrentEnvironment(env))
  return db
}

const AuthContext = createContext<Context | null>(null)

/**
 * @deprecated - use AuthStore.ts instead
 */
export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context)
    throw new Error(
      "AuthContext must be called from within the AuthContextProvider"
    )
  return context
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within AuthProvider")
  }
  return context
}

// Start of Context Provider
export const AuthContextProvider = ({ children }: Props) => {
  const [isFirebaseLogin, setIsFirebaseLogin] =
    useLocalStorage<AuthProviderState>("isFirebaseLogin", {
      dev: "workos",
      prod: "workos",
    })

  const { user, setUser } = useAuthStore()
  const { token, setToken } = useAuthStore()
  const { env, setEnv } = useAuthStore()

  const handleClearData = useCallback(
    (env: environmentType, where?: string) => {
      const newToken = {
        ...token,
        [env]: null,
      }

      const newUser = {
        ...user,
        [env]: null,
      }

      setToken(newToken)
      setUser(newUser)
      return
    },
    [setToken, setUser, token, user]
  )

  // Refresh token background interval
  // Automatically refresh token if it is expired
  // If error, clear data and navigate back to login page
  useRefreshWorkosToken({
    onError: () => {
      handleClearData(env, "Refresh Workos Token")
      setRedirect("/login")
    },
  })

  const [loaded, setLoaded] = useState(false)
  const [error] = useState("")
  const [redirect, setRedirect] = useState<string | null>(null)
  // const [focused, setFocused] = useState(false)
  const auth = getAuth(getCurrentEnvironment(env))

  const AuthCheck = useCallback(
    () =>
      onAuthStateChanged(auth, async (user) => {
        if (!loaded) {
          if (user) {
            const currentToken = await user
              .getIdToken(false)
              .then((token) => token)
            const vizznUser = await initializeAuthUserData({
              userId: user.uid,
              env,
              token: currentToken,
            }).catch((error) => message.error(error.message))

            if (!vizznUser || !vizznUser.roleNames.includes("sysadmin")) {
              setLoaded(true)
              message.error("User must be a sysadmin")
              return
            }
            const localToken = token

            const newToken = {
              ...localToken,
              [env]: currentToken,
            }

            setToken(newToken)
            setLoaded(true)
          } else {
            handleClearData(env, "Auth Check")
            setLoaded(true)
          }
        }
      }),
    [auth, env, handleClearData, loaded, setToken, token]
  )

  const tokenChanged = useCallback(() => {
    const envs: environmentType[] = ["dev", "prod"]
    for (let key of envs) {
      const auth = getAuth(getCurrentEnvironment(key as environmentType))
      onIdTokenChanged(auth, (user) => {
        if (user)
          user.getIdToken(false).then((value) => {
            const newToken = {
              ...token,
              [key]: value,
            }
            setToken(newToken)
          })
      })
    }
  }, [token, setToken])

  useEffect(() => {
    // Only run functions if using Firebase login
    if (isFirebaseLogin[env] === "firebase") {
      // Run both functions immediately on mount
      tokenChanged()
      AuthCheck()

      // Set up intervals
      const tokenInterval = setInterval(tokenChanged, 60000) // every minute
      const authCheckInterval = setInterval(AuthCheck, 60000) // every minute

      // Cleanup intervals on unmount
      return () => {
        clearInterval(tokenInterval)
        clearInterval(authCheckInterval)
      }
    }
  }, [tokenChanged, isFirebaseLogin, env, AuthCheck])

  const handleSetEnv = (envName: environmentType) => {
    setEnv(envName)
    setLoaded(false)
  }

  const handleLogin = async (
    email: string,
    password: string,
    navigate?: () => void
  ) => {
    message.loading("login in..", 0.25)
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (loginUser) => {
        const currentToken = await loginUser.user.getIdToken(false)
        const vizznUser = await initializeAuthUserData({
          userId: loginUser.user.uid, // authid
          env,
          token: currentToken,
        }).catch((error) => {
          handleClearData(env, "Login")
          message.error(error.message)
          return null
        })

        if (!vizznUser || !vizznUser.roleNames.includes("sysadmin")) {
          return message.error("User must be a sysadmin")
        }

        message.success("Login successful!", 0.25)
        const localUser = user

        const newUser = {
          ...localUser,
          [env]: vizznUser,
        }

        const newToken = {
          ...token,
          [env]: currentToken,
        }

        setUser(newUser)
        setToken(newToken)

        navigate && navigate()
      })
      .catch((error) => {
        handleClearData(env, "Login")
        message.error(error.message)
      })
  }

  const workosLogin = async (body: { email: string; password: string }) => {
    const response = await axios.post<WorkosLoginResponse>(
      `${getEnvUrl(env)}/workos/login-v2`,
      // `${getEnvUrl(env)}/workos/login-v2`,
      body
    )
    return response.data
  }

  const handleWorkosLogin = async (body: {
    email: string
    password: string
    navigate?: () => void
  }) => {
    try {
      const data: WorkosLoginResponse = await workosLogin(body)

      const vizznUser = await getUserAccountV2({
        id: data.user.id,
        token: data.jwt,
        env,
      })

      if (!vizznUser) return { user: null, token: null }
      if (!vizznUser.roleNames.includes("sysadmin")) {
        message.error("User must be a sysadmin")
        return { user: null, token: null }
      }

      const uid = vizznUser.authId
      const vizznUserWithUid = { ...vizznUser, uid }

      // Batch all state updates together to ensure consistency
      const newState = {
        token: data.jwt,
        user: vizznUserWithUid,
      }

      const localToken = token
      const localUser = user

      const newToken = {
        ...localToken,
        [env]: newState.token,
      }
      const newUser = {
        ...localUser,
        [env]: newState.user,
      }

      return {
        user: newUser,
        token: newToken,
      }
      // body.navigate && body.navigate()
    } catch (error: any) {
      handleClearData(env, "Workos Login")

      message.error(error.error)
    }
  }

  const handleWorkosLogout = async (body: { navigate?: () => void }) => {
    try {
      await useAuthStore.getState().logout(env)
      if (body.navigate) body.navigate()
    } catch (error: any) {
      message.error("Logout failed. Please try again.")
    }
  }

  return (
    <AuthContext.Provider
      value={{
        env,
        error,
        handleLogin,
        handleSetEnv,
        user,
        token,
        setToken,
        redirect,
        setRedirect,
        getDb,
        setUser,
        isFirebaseLogin,
        setIsFirebaseLogin,
        handleWorkosLogin,
        handleWorkosLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
