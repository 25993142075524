import React, { useMemo, useState } from "react"
import { Button, Modal, Tooltip, Row, Col } from "antd"

import { InfoCircleOutlined } from "@ant-design/icons"
import { entityType } from "../../../utils/utils"
import GearIcon from "../../../VizznIcons/GearIcon"
import {
  NotificationSubscriptionBucket,
  NotificationUserBucketPreference,
} from "../../../types/Subscription"
import PreferenceTagWrapper from "../partials/PreferenceTagWrapper"
import { TokenState } from "../../../types/user"
import { environmentType } from "../../../types/environtment"

type Props = {
  systemBuckets: NotificationSubscriptionBucket[]
  userBucketPreferences: NotificationUserBucketPreference[]
  refetchSystemBuckets: () => void
  refetchUserBucketPreferences: () => void
  isRefetching: boolean
  userId: string
  env: environmentType
  token: TokenState
}

export const PreferenceModal = ({
  systemBuckets,
  userBucketPreferences,
  userId,
  token,
  env,
}: Props) => {
  const [isVisible, setIsVisible] = useState(false)

  const groupByEntityType = (buckets: NotificationSubscriptionBucket[]) => {
    return buckets.reduce((acc, bucket) => {
      const { entityType } = bucket
      if (!acc[entityType]) {
        acc[entityType] = []
      }
      acc[entityType].push(bucket)
      return acc
    }, {} as { [key: string]: NotificationSubscriptionBucket[] })
  }

  const groupedBuckets = useMemo(
    () => groupByEntityType(systemBuckets),
    [systemBuckets]
  )

  const filteredEntityType = useMemo(() => {
    return entityType.filter(
      (e) => groupedBuckets[e.type] && groupedBuckets[e.type].length > 0
    )
  }, [groupedBuckets])

  return filteredEntityType[0] ? (
    <div>
      <Tooltip
        title={`${filteredEntityType[0]?.label} Default Subscriptions Preferences`}
      >
        <Button
          style={{ border: "none", background: "none" }}
          onClick={(e: any) => {
            e.stopPropagation()
            setIsVisible(true)
          }}
          icon={<GearIcon color="#c7c4c4" />}
        ></Button>
      </Tooltip>
      <Modal
        title={
          <>
            <GearIcon color="#c7c4c4" /> <b>{filteredEntityType[0]?.label}</b> -
            Default Subscriptions Preferences
          </>
        }
        open={isVisible}
        onCancel={() => setIsVisible(false)}
        width={800}
        footer={false}
      >
        {filteredEntityType.map((e) => {
          const systemBuckets = groupedBuckets[e.type] || []
          if (systemBuckets.length === 0) return null

          return (
            <div key={e.type} style={{ marginBottom: "20px" }}>
              <Row gutter={[16, 16]} style={{ overflowY: "auto" }}>
                {systemBuckets
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((bucket) => {
                    const userBuckets = Array.isArray(userBucketPreferences)
                      ? userBucketPreferences.find(
                          (item) => item.entityType === e.type
                        )
                      : undefined
                    const selectedNotificationBuckets =
                      userBuckets?.notiBuckets?.find((u) => u.id === bucket.id)
                    const selectedFeedBuckets = userBuckets?.feedBuckets?.find(
                      (u) => u.id === bucket.id
                    )

                    return (
                      <Col
                        span={24}
                        key={bucket.id}
                        style={{ padding: "0 8px" }}
                      >
                        <div
                          className="subscription-preference"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color:
                                selectedNotificationBuckets ||
                                selectedFeedBuckets
                                  ? "#19b1b4"
                                  : "#000",
                            }}
                          >
                            {bucket.name}
                            {bucket.description && (
                              <Tooltip title={bucket.description}>
                                <InfoCircleOutlined
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "17px",
                                  }}
                                />
                              </Tooltip>
                            )}
                          </div>

                          <div style={{ display: "flex" }}>
                            <PreferenceTagWrapper
                              selected={Boolean(selectedNotificationBuckets)}
                              userBucketPreferences={userBucketPreferences}
                              bucketType={e.type}
                              bucketId={bucket.id}
                              columnType={"notification"}
                              userId={userId}
                              env={env}
                              token={token}
                            />
                            <PreferenceTagWrapper
                              selected={Boolean(selectedFeedBuckets)}
                              userBucketPreferences={userBucketPreferences}
                              bucketType={e.type}
                              bucketId={bucket.id}
                              columnType={"feed"}
                              userId={userId}
                              env={env}
                              token={token}
                            />
                          </div>
                        </div>
                      </Col>
                    )
                  })}
              </Row>
            </div>
          )
        })}
      </Modal>
    </div>
  ) : (
    <></>
  )
}
