import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { Login } from "./pages/Login"
import { SideNav } from "./components/SideNav"
import { ConfigProvider, Layout, theme } from "antd"
import { LayoutHeader } from "./components/common/LayoutHeader"
import { AnimatePresence } from "framer-motion"
import { useAuthStore } from "./stores/AuthStore"

function App() {
  const { theme: themeName } = useAuthStore()

  const lightTheme = {
    colorPrimary: "#424bd1",
    colorGraphBg: "#f5f5f5",
    colorText: "#000",
    colorBgBase: "#ffffff",
    colorBgContainer: "#ffffff",
  }

  const darkTheme = {
    colorPrimary: "#424bd1",
    colorText: "#fff",
    colorBgBase: "#151525",
    colorGraphBg: "#151525",
    colorBgContainer: "#1a1a2e",
  }

  return (
    <Router>
      <AnimatePresence>
        <ConfigProvider
          theme={{
            token: themeName === "dark" ? darkTheme : lightTheme,
            algorithm:
              themeName === "dark"
                ? theme.darkAlgorithm
                : theme.defaultAlgorithm,
          }}
        >
          <Layout style={{ minHeight: "100vh" }}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/*"
                element={
                  <ProtectedRoute>
                    <Layout>
                      <LayoutHeader />
                      <Layout>
                        <SideNav />
                      </Layout>
                    </Layout>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Layout>
        </ConfigProvider>
      </AnimatePresence>
    </Router>
  )
}

export default App
