import {
  ApartmentOutlined,
  AppstoreOutlined,
  AreaChartOutlined,
  BankFilled,
  BankOutlined,
  BellFilled,
  CopyOutlined,
  DatabaseFilled,
  DotChartOutlined,
  ExperimentFilled,
  FlagFilled,
  FormOutlined,
  GlobalOutlined,
  HomeFilled,
  IdcardOutlined,
  PushpinOutlined,
  StarFilled,
  TeamOutlined,
  TranslationOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { Layout, Menu } from "antd"
import { Link, Route, Routes, useLocation } from "react-router-dom"
import { AuthCheck } from "../pages/AuthCheck"
import DeploymentStatus from "../pages/DeploymentStatus/DeploymentStatus"
import DispatchExplorer from "../pages/DispatchExplorer"
import EnterpriseDetailPage from "../pages/enterprises/EnterpriseDetailPage"
import EnterpriseListPage from "../pages/enterprises/EnterpriseListPage"
import { EntitySearch } from "../pages/EntitySearch"
import { FeatureFlags } from "../pages/FeatureFlags"
import { Home } from "../pages/Home"
import { Login } from "../pages/Login"
import { NotificationPage } from "../pages/NotificationPage"
import { NotificationPropertyBucket } from "../pages/NotificationPropertyBucket"
import { NotificationTaxonomy } from "../pages/NotificationTaxonomy"
import { Services } from "../pages/Services"
import { ServicesDetail } from "../pages/ServicesDetail"
import ShortcutCompliance from "../pages/ShortcutCompliance"
import SysAdminDetailPage from "../pages/SysAdmin/SysAdminDetailPage"
import SysAdminPage from "../pages/SysAdmin/SysAdminPage"
import TeamApplications from "../pages/TeamApplications"
import TeamApplicationsDetail from "../pages/TeamApplicationsDetail"
import TeamMaps from "../pages/TeamMaps/TeamMaps"
import TeamMetrics from "../pages/TeamMetrics"
import { TeamPage } from "../pages/TeamPage"
import { TeamSearch } from "../pages/TeamSearch"
import { TopicsPage } from "../pages/TopicsPage"
import { TranslationLists } from "../pages/Translations/TranslationLists"
import UsageScores from "../pages/UsageScores/UsageScoresPage"
import UsagesScoresDetail from "../pages/UsageScores/UsagesScoresDetail"
import UsageScoresTopByCategory from "../pages/UsageScoresTopByCategory/UsageScoresTopByCategory"
import { UserPage } from "../pages/UserPage"
import { UserSearch } from "../pages/UserSearch"
import { VizznUniversityArticles } from "../pages/VizznUniversityArticles"
import { VizznUniversityVideos } from "../pages/VizznUniversityVideos"
import { WeeklyMetrics } from "../pages/WeeklyMetrics"
import { useAuthStore } from "../stores/AuthStore"
import { getEnvUrl } from "../utils/utils"
import CompanyApplicationDetail from "./Applications/CompanyApplicationDetail"
import CompanyApplications from "./Applications/CompanyApplications"
import EmployeeApplications from "./Applications/EmployeeApplications"
import { MotionWrapper } from "./Motion/MotionWrapper"

export const SideNav = () => {
  const { env } = useAuthStore()
  const location = useLocation()
  const handleSimulator = () => {
    localStorage.setItem("envUrl", getEnvUrl(env))
  }

  return (
    <>
      <Layout.Sider
        collapsible
        style={{
          overflow: "auto",
          height: "calc(100vh - 99px)",
          position: "sticky",
          left: 0,
          top: 99,
          bottom: 0,
          paddingBottom: "84px",
          scrollbarColor: "#505af1 #505af1",
        }}
      >
        <Menu
          mode="inline"
          selectedKeys={[location.pathname.split("/")[1]]}
          style={{ minHeight: "100%" }}
        >
          <Menu.Item icon={<HomeFilled />} key="dashboard">
            <Link to="/">Dashboard</Link>
          </Menu.Item>
          <Menu.Item icon={<PushpinOutlined />} key="sysadmin">
            <Link to="/sysadmin">SysAdmin</Link>
          </Menu.Item>
          <Menu.Item icon={<UserOutlined />} key="users">
            <Link to="/users">User Search</Link>
          </Menu.Item>

          <Menu.SubMenu
            key="companies-menu"
            title="Companies"
            icon={<BankFilled />}
          >
            <Menu.Item icon={<ApartmentOutlined />} key="enterprises">
              <Link to="/enterprises">Enterprises</Link>
            </Menu.Item>
            <Menu.Item icon={<TeamOutlined />} key="teams">
              <Link to="/teams">Search</Link>
            </Menu.Item>

            <Menu.Item icon={<GlobalOutlined />} key="team-maps">
              <Link to="/team-maps">Map</Link>
            </Menu.Item>

            <Menu.Item icon={<DotChartOutlined />} key="team-metrics">
              <Link to="/team-metrics">Metrics</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="applications-menu"
            title="Applications"
            icon={<UserAddOutlined />}
          >
            <Menu.Item icon={<FormOutlined />} key="applications">
              <Link to="/applications">Team Applications</Link>
            </Menu.Item>
            <Menu.Item icon={<BankOutlined />} key="company-applications">
              <Link to="/company-applications">Company Applications</Link>
            </Menu.Item>

            <Menu.Item icon={<IdcardOutlined />} key="employee-applications">
              <Link to="/employee-applications">Employee Applications</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item icon={<AppstoreOutlined />} key="entities">
            <Link to="/entities">Entity Search</Link>
          </Menu.Item>
          <Menu.SubMenu
            key="university"
            icon={<StarFilled />}
            title="Vizzn University"
          >
            <Menu.Item key="article">
              <Link to="/university-articles">Articles</Link>
            </Menu.Item>
            <Menu.Item key="videos">
              <Link to="/university-videos">Videos</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item icon={<DotChartOutlined />} key="dispatch-explorer">
            <Link to="/dispatch-explorer">Dispatch Explorer</Link>
          </Menu.Item>

          <Menu.SubMenu key="usage" title="Usage" icon={<AreaChartOutlined />}>
            <Menu.Item icon={<AreaChartOutlined />} key="usage-scores">
              <Link to="/usage-category">Usage Categories</Link>
            </Menu.Item>
            <Menu.Item
              icon={<AreaChartOutlined />}
              key="usage-scores-top-category"
            >
              <Link to="/top-by-category">Top Usage by Category</Link>
            </Menu.Item>
            <Menu.Item icon={<AreaChartOutlined />} key="weekly-metrics">
              <Link to="/weekly-metrics">Weekly Metrics</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item icon={<FlagFilled />} key="featureflags">
            <Link to="/feature-flags">Feature Flags</Link>
          </Menu.Item>
          <Menu.Item icon={<TranslationOutlined />} key="translations">
            <Link to="/translation">Translations</Link>
          </Menu.Item>

          <Menu.SubMenu
            key="serviceTopics"
            title="Services"
            icon={<DatabaseFilled />}
          >
            <Menu.Item key="services">
              <Link to="/services">Services</Link>
            </Menu.Item>
            <Menu.Item key="topics">
              <Link to="/topics">Topics</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key="utils" title="Utils" icon={<ExperimentFilled />}>
            <Menu.Item onClick={handleSimulator} key="location">
              <a href="locationSimulator.html" target="_blank">
                Location Simulator
              </a>
            </Menu.Item>
            <Menu.Item key="authcheck">
              <Link to="/authcheck">Auth Check</Link>
            </Menu.Item>
            <Menu.Item key="delpoyment-status">
              <Link to="/delpoyment-status">Deployment Status</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="notification"
            title="Notification"
            icon={<BellFilled />}
          >
            <Menu.Item key="notification-property-bucket">
              <Link to="/notification/property-bucket">
                Bucket Configuration
              </Link>
            </Menu.Item>
            <Menu.Item key="Notifications">
              <Link to="/notification">Logs</Link>
            </Menu.Item>

            {/* <Menu.Item key="taxonomy">
              <Link to="/notification/taxonomy">Taxonomy</Link>
            </Menu.Item> */}
          </Menu.SubMenu>

          <Menu.Item icon={<DotChartOutlined />} key="shortcut-compliance">
            <Link to="/shortcut-compliance">Shortcut Compliance</Link>
          </Menu.Item>

          <Menu.Item icon={<CopyOutlined />} key="changelogs">
            <a
              href="https://github.com/Vizzn/admin-app-v1/blob/main/CHANGELOG.md"
              target="_blank"
              rel="noreferrer"
            >
              Change Logs
            </a>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout.Content
        style={{
          paddingRight: 24,
          minHeight: "100vh",
        }}
      >
        <div style={{ marginTop: 113, paddingLeft: 24 }}>
          <MotionWrapper>
            {
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sysadmin" element={<SysAdminPage />} />
                <Route path="/sysadmin/:id" element={<SysAdminDetailPage />} />
                <Route path="/feature-flags" element={<FeatureFlags />} />
                <Route path="/translation/" element={<TranslationLists />} />
                <Route path="/authcheck" element={<AuthCheck />} />
                <Route path="/teams/:id" element={<TeamPage />} />
                <Route path="/teams" element={<TeamSearch />} />
                <Route path="/team-maps" element={<TeamMaps />} />
                <Route path="/team-metrics" element={<TeamMetrics />} />

                <Route path="/enterprises" element={<EnterpriseListPage />} />
                <Route
                  path="/enterprises/:id"
                  element={<EnterpriseDetailPage />}
                />

                <Route path="/applications" element={<TeamApplications />} />
                <Route
                  path="/employee-applications"
                  element={<EmployeeApplications />}
                />
                <Route
                  path="/company-applications"
                  element={<CompanyApplications />}
                />
                <Route
                  path="/company-applications/:id"
                  element={<CompanyApplicationDetail />}
                />
                <Route
                  path="/dispatch-explorer"
                  element={<DispatchExplorer />}
                />

                <Route path="/usage-category" element={<UsageScores />} />
                <Route
                  path="/usage-category/:id/:name"
                  element={<UsagesScoresDetail />}
                />
                <Route
                  path="/top-by-category"
                  element={<UsageScoresTopByCategory />}
                />
                <Route path="/weekly-metrics" element={<WeeklyMetrics />} />

                <Route
                  path="/applications/:id"
                  element={<TeamApplicationsDetail />}
                />

                <Route
                  path="/notification/property-bucket"
                  element={<NotificationPropertyBucket />}
                />
                <Route
                  path="/notification/taxonomy"
                  element={<NotificationTaxonomy />}
                />
                <Route
                  path="/delpoyment-status"
                  element={<DeploymentStatus />}
                />
                <Route path="/notification" element={<NotificationPage />} />
                <Route path="/users/:id" element={<UserPage />} />
                <Route path="/users" element={<UserSearch />} />
                <Route path="/entities" element={<EntitySearch />} />

                <Route
                  path="/university-articles"
                  element={<VizznUniversityArticles />}
                />
                <Route
                  path="/university-videos"
                  element={<VizznUniversityVideos />}
                />
                <Route
                  path="/shortcut-compliance"
                  element={<ShortcutCompliance />}
                />
                <Route path="/services/:id" element={<ServicesDetail />} />
                <Route path="/services" element={<Services />} />
                <Route path="/topics" element={<TopicsPage />} />

                <Route path="login" element={<Login />} />
              </Routes>
            }
          </MotionWrapper>
        </div>
      </Layout.Content>
    </>
  )
}
