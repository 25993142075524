import { useQuery } from "@tanstack/react-query"
import { environmentType } from "../types/environtment"
import axios from "axios"
import {
  OwnersLabels,
  SheetPayload,
  StoryComplianceReport,
  StoryReportPayload,
} from "../types/ShortcutCompliance"
import { message } from "antd"
import { getEnvUrl } from "../utils/utils"

export const useUpdateShortcutVizznUsers = (body: {
  env: environmentType
  token: string | null
}) => {
  return useQuery({
    queryKey: ["updateShortcutVizznUsers", body.env],
    queryFn: () => updateShortcutVizznUsers(body),
  })
}

const updateShortcutVizznUsers = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get(
    `${getEnvUrl(body.env)}/v1/shortcut-vizzn-user-data`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}

export const useGetVizznStoryComplianceReport = (body: {
  env: environmentType
  token: string | null
  payload: StoryReportPayload
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ["getVizznStoryReport", body.env, body.payload],
    queryFn: () => getVizznStoryComplianceReport(body),
    enabled: body.enabled,
  })
}

const getVizznStoryComplianceReport = (body: {
  env: environmentType
  token: string | null
  payload: StoryReportPayload
}) => {
  const data = axios.post<StoryComplianceReport>(
    `${getEnvUrl(body.env)}/v1/vizzn-story-compliance-report`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}

export const useAddVizznStoryComplianceReportToSheet = (body: {
  env: environmentType
  token: string | null
  payload: SheetPayload
}) => {
  return useQuery({
    queryKey: ["addVizznStoryComplianceReportToSheet", body.env, body.payload],
    queryFn: () => addVizznStoryComplianceReportToSheet(body),
    enabled: false,
  })
}

const addVizznStoryComplianceReportToSheet = async (body: {
  env: environmentType
  token: string | null
  payload: SheetPayload
}) => {
  const { data } = await axios.post(
    `${getEnvUrl(body.env)}/v1/add-vizzn-story-compliance-to-sheet`,
    body.payload,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  if (data) {
    message.success(data)
  }
  return data
}

export const useGetVizznShortcutOwnersLabels = (body: {
  env: environmentType
  token: string | null
  enabled: boolean
}) => {
  return useQuery({
    queryKey: ["getVizznShortcutOwnersLabels", body.env],
    queryFn: () => getVizznShortcutOwnersLabels(body),
    enabled: body.enabled,
  })
}

const getVizznShortcutOwnersLabels = async (body: {
  env: environmentType
  token: string | null
}) => {
  const { data } = await axios.get<OwnersLabels>(
    `${getEnvUrl(body.env)}/v1/vizzn-shortcut-owners-labels`,
    {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
    }
  )
  return data
}
