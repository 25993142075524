import { format } from "date-fns"
import { TimeDisplay } from "../../../types/Team"

export const dateDisplay = (
  date: Date | string | number,
  formatType:
    | "fullHourAndDate"
    | "fullDateAndHour"
    | "fullDateAndHms"
    | "fullDate"
    | "fullDateCsv"
    | "fullYearAndMonth"
    | "hourOnly"
    | "",
  timeDisplay?: TimeDisplay
) => {
  if (!date) return ""
  const formatDate = (date: Date | string | number) => {
    const formattedDate = format(
      new Date(date),
      timeDisplay === "hour12" ? "h:mm a" : "HH:mm"
    )

    switch (formatType) {
      case "fullHourAndDate":
        return `${formattedDate} (${format(new Date(date), "MMM do, yyyy")})`
      case "fullDateAndHour":
        return `(${format(new Date(date), "MMM do, yyyy")}) ${formattedDate}`
      case "fullDateAndHms":
        return `(${format(
          new Date(date),
          "MMM do, yyyy"
        )}) ${formattedDate}:${format(new Date(date), "ss")}`
      case "fullDate":
        return `(${format(new Date(date), "MMM do, yyyy")})`
      case "fullYearAndMonth":
        return format(new Date(date), "yyyy-MM-dd-HH-mm-ss")
      case "fullDateCsv":
        return `${format(new Date(date), "MMM do, yyyy")}`
      case "hourOnly":
        return formattedDate
      default:
        return format(new Date(date), "yyyy-MM-dd")
    }
  }

  return formatDate(date)
}
