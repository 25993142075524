import {
  ActivityFeedsPropData,
  DispatchType,
} from "../../../types/DispatchExplorer"
import { useAuthContext } from "../../../context/AuthContext"

export const DispatchActivityFeed = ({
  type,
  data,
}: {
  type?: DispatchType

  data: ActivityFeedsPropData
}) => {
  const { env } = useAuthContext()

  return (
    <>
      <a
        className="markdown-link"
        href={`${
          env === "dev" ? "https://app.vizzn-dev.ca/" : "https://app.vizzn.ca/"
        }${
          type ? `/${type}-detail-page/${data.id}` : `/dispatches/${data.id}`
        }`}
      >
        {String(data.value)}
      </a>
    </>
  )
}
