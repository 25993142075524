import { Input, Modal, Button } from "antd"
import { useAuthStore } from "../../stores/AuthStore"
import { useState } from "react"

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function ForceSetToken({ open, setOpen }: Props) {
  const { setToken, token } = useAuthStore()
  const [devToken, setDevToken] = useState(token?.dev || "")
  const [prodToken, setProdToken] = useState(token?.prod || "")

  const handleSetToken = (environment: "dev" | "prod") => {
    const tokenToSet = environment === "dev" ? devToken : prodToken
    setToken({ ...token, [environment]: tokenToSet })
    setOpen(false)
  }

  return (
    <Modal
      title="Set Authentication Tokens"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <div className="space-y-4">
        <div>
          <p>Current Token:</p>
        </div>

        <div className="space-y-2">
          <h4>Development Token</h4>
          <Input.Group compact>
            <Input
              style={{ width: "calc(100% - 100px)" }}
              value={devToken}
              onChange={(e) => setDevToken(e.target.value)}
              placeholder="Enter dev token"
            />
            <Button type="primary" onClick={() => handleSetToken("dev")}>
              Set Dev
            </Button>
          </Input.Group>
        </div>

        <div className="space-y-2">
          <h4>Production Token</h4>
          <Input.Group compact>
            <Input
              style={{ width: "calc(100% - 100px)" }}
              value={prodToken}
              onChange={(e) => setProdToken(e.target.value)}
              placeholder="Enter prod token"
            />
            <Button type="primary" onClick={() => handleSetToken("prod")}>
              Set Prod
            </Button>
          </Input.Group>
        </div>
      </div>
    </Modal>
  )
}
