export interface HttpStatusResponse {
  status: number
  title: string
  detail: string
  type?: HttpStatusType
}

export enum HttpStatusType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  FORBIDDEN = 'FORBIDDEN',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  ACCESS_FORBIDDEN = 'ACCESS_FORBIDDEN',
  TOKEN_INVALID = 'TOKEN_INVALID',
  NOT_FOUND = 'NOT_FOUND',
}

export enum HttpStatusStatusString {
  UNAUTHORIZED = 'Unauthorized',
  FORBIDDEN = 'Forbidden',
  TOKEN_EXPIRED = 'Token has expired',
  ACCESS_FORBIDDEN = 'Access to this resource is forbidden',
  NOT_FOUND = 'Not Found',
  TOKEN_INVALID = 'Token is invalid',
}

export enum HttpStatus {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
}

export const HttpStatusMessages: Record<HttpStatusType, HttpStatusResponse> = {
  [HttpStatusType.TOKEN_EXPIRED]: {
    status: 401,
    title: HttpStatusStatusString.UNAUTHORIZED,
    detail: HttpStatusStatusString.TOKEN_EXPIRED,
    type: HttpStatusType.TOKEN_EXPIRED,
  },
  [HttpStatusType.ACCESS_FORBIDDEN]: {
    status: 403,
    title: HttpStatusStatusString.FORBIDDEN,
    detail: HttpStatusStatusString.ACCESS_FORBIDDEN,
    type: HttpStatusType.ACCESS_FORBIDDEN,
  },
  [HttpStatusType.TOKEN_INVALID]: {
    status: 401,
    title: HttpStatusStatusString.UNAUTHORIZED,
    detail: HttpStatusStatusString.TOKEN_INVALID,
    type: HttpStatusType.TOKEN_INVALID,
  },
  [HttpStatusType.NOT_FOUND]: {
    status: 404,
    title: HttpStatusStatusString.NOT_FOUND,
    detail: HttpStatusStatusString.NOT_FOUND,
    type: HttpStatusType.NOT_FOUND,
  },
  [HttpStatusType.UNAUTHORIZED]: {
    status: 401,
    title: HttpStatusStatusString.UNAUTHORIZED,
    detail: HttpStatusStatusString.UNAUTHORIZED,
    type: HttpStatusType.UNAUTHORIZED,
  },
  [HttpStatusType.FORBIDDEN]: {
    status: 403,
    title: HttpStatusStatusString.FORBIDDEN,
    detail: HttpStatusStatusString.FORBIDDEN,
    type: HttpStatusType.FORBIDDEN,
  },
}
