import { BellFilled } from "@ant-design/icons"
import { message, Tag } from "antd"
import React from "react"
import { PulseIcon } from "../../../VizznIcons/PulseIcon"
import { NotificationUserBucketPreference } from "../../../types/Subscription"
import {
  useCreateUserBucketPreference,
  useUpdateUserBucketPreference,
} from "../../../apis/subscriptions"
import { environmentType } from "../../../types/environtment"
import { TokenState } from "../../../types/user"

type Props = {
  bucketId: string
  selected: boolean
  userBucketPreferences: NotificationUserBucketPreference[]
  bucketType: string
  columnType: "feed" | "notification"
  userId: string
  token: TokenState
  env: environmentType
}

export default function PreferenceTagWrapper({
  selected,
  userBucketPreferences,
  bucketId,
  bucketType,
  columnType,
  userId,
  token,
  env,
}: Props) {
  const { mutateAsync: updateUserBucketPreference } =
    useUpdateUserBucketPreference(userId)
  const { mutateAsync: createUserBucketPreference } =
    useCreateUserBucketPreference()

  const handleUpdateBucketPreference = async (
    preferences: NotificationUserBucketPreference[],
    entityType: string,
    bucketId: string,
    type: "notification" | "feed",
    action: "add" | "delete"
  ) => {
    // If the user doesn't have the preference bucket created, create it
    // If the user has the preference bucket created, update it
    const isPreferenceInitialized = preferences.find(
      (item) => item.entityType === entityType
    )

    const currentBucket = preferences.find(
      (item) => item.entityType === entityType
    )
    const currentFeedBucketIds =
      currentBucket?.feedBuckets?.map((b) => b.id) || []
    const currentNotiBucketIds =
      currentBucket?.notiBuckets?.map((b) => b.id) || []

    const feedBucketIds =
      columnType === "feed"
        ? action === "add"
          ? [...currentFeedBucketIds, bucketId]
          : currentFeedBucketIds.filter((id) => id !== bucketId)
        : currentFeedBucketIds

    const notiBucketIds =
      columnType === "notification"
        ? action === "add"
          ? [...currentNotiBucketIds, bucketId]
          : currentNotiBucketIds.filter((id) => id !== bucketId)
        : currentNotiBucketIds

    try {
      if (!isPreferenceInitialized) {
        await createUserBucketPreference({
          userId: userId,
          feedBucketIds: feedBucketIds,
          notiBucketIds: notiBucketIds,
          entityType: entityType,
          env: process.env.REACT_APP_ENV as environmentType,
          token: token,
        })
        return
      }

      await updateUserBucketPreference({
        bucketId: currentBucket?.id || `${userId}.${entityType}`,
        feedBucketIds: feedBucketIds,
        notiBucketIds: notiBucketIds,
        env: env,
        token: token,
      })
    } catch (error) {
      message.error("failed to update bucket preference")
    }
  }

  if (selected && columnType === "notification") {
    return (
      <Tag
        style={{ borderColor: "#19b1b4", cursor: "pointer" }}
        onClick={() =>
          handleUpdateBucketPreference(
            userBucketPreferences,
            bucketType,
            bucketId,
            columnType,
            "delete"
          )
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong style={{ marginRight: "5px", color: "#19b1b4" }}>
            {" "}
            Notification
          </strong>
          <BellFilled style={{ color: "#19b1b4" }} />
        </div>
      </Tag>
    )
  }
  if (selected && columnType === "feed") {
    return (
      <Tag
        style={{ borderColor: "#19b1b4", cursor: "pointer" }}
        onClick={() =>
          handleUpdateBucketPreference(
            userBucketPreferences,
            bucketType,
            bucketId,
            columnType,
            "delete"
          )
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong style={{ marginRight: "5px", color: "#19b1b4" }}>
            Activity Feed
          </strong>
          <div>
            <PulseIcon color="#19b1b4" width="15px" />
          </div>
        </div>
      </Tag>
    )
  }
  if (!selected && columnType === "notification") {
    return (
      <Tag
        style={{ cursor: "pointer" }}
        onClick={() =>
          handleUpdateBucketPreference(
            userBucketPreferences,
            bucketType,
            bucketId,
            columnType,
            "add"
          )
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong style={{ marginRight: "5px" }}> Notification</strong>
          <BellFilled style={{ color: "#afafaf" }} />
        </div>
      </Tag>
    )
  }
  if (!selected && columnType === "feed") {
    return (
      <Tag
        style={{ cursor: "pointer" }}
        onClick={() =>
          handleUpdateBucketPreference(
            userBucketPreferences,
            bucketType,
            bucketId,
            columnType,
            "add"
          )
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <strong style={{ marginRight: "5px" }}> Activity Feed</strong>
          <div style={{ cursor: "pointer" }}>
            <PulseIcon color="#afafaf" width="15px" />
          </div>
        </div>
      </Tag>
    )
  }
  return null
}
