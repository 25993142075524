import { Tabs } from "antd"
import Users from "../components/ShortcutCompliance/Users"
import Report from "../components/ShortcutCompliance/Report"

export default function ShortcutCompliance() {
  return (
    <>
      <Tabs>
        <Tabs.TabPane key="Report" tab="Report">
          <Report />
        </Tabs.TabPane>
        <Tabs.TabPane key="Users" tab="Users">
          <Users />
        </Tabs.TabPane>
      </Tabs>
    </>
  )
}
