import { Input, Form, Button, Table, Tag, Row, Col, Divider } from "antd"
import { FilterOutlined } from "@ant-design/icons"
import {
  Label,
  MainDeveloper,
  Report,
  StoryReportPayload,
} from "../../types/ShortcutCompliance"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/AuthContext"
import { useGetVizznStoryComplianceReport } from "../../apis/ShortcutCompliance"
import dayjs from "dayjs"
import FilterModal from "./FilterModal"
import ExportButton from "./ExportButton"

export default function Reports() {
  const { env, token } = useAuthContext()
  const [form] = Form.useForm()

  const showFilterModal = () => {
    setIsFilterModalVisible(true)
  }

  const handleOk = () => {
    form.validateFields().then((values) => {
      setStoryReportPayload({
        startDate:
          values.dateRange && values.dateRange[0]
            ? values.dateRange[0].format("YYYY-MM-DD")
            : "*",
        endDate:
          values.dateRange && values.dateRange[1]
            ? values.dateRange[1].format("YYYY-MM-DD")
            : "*",
        storyIDs: (values.storyIds || []).map((id: string) => Number(id)),
        owners: values.owners,
        labels: values.labels,
        offset: 0,
      })
      setReportData([])
      setSelectedRowKeys([])
      setIsFilterModalVisible(false)
    })
  }

  const handleCancel = () => {
    setIsFilterModalVisible(false)
  }

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [reportData, setReportData] = useState<Report[]>([])
  const [moreResults, setMoreResults] = useState(false)

  const [storyReportPayload, setStoryReportPayload] =
    useState<StoryReportPayload>({
      startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      storyIDs: [],
      owners: [],
      labels: [],
      offset: 0,
    })

  const storyComplianceReport = useGetVizznStoryComplianceReport({
    env,
    token: token[env],
    payload: storyReportPayload,
    enabled: storyReportPayload.offset > 0 && moreResults,
  })

  useEffect(() => {
    if (storyComplianceReport.data) {
      setReportData((prev) => [
        ...prev,
        ...storyComplianceReport.data.data.report,
      ])
      setSelectedRowKeys((prev) => [
        ...prev,
        ...(storyComplianceReport.data.data.report.map((item) => item.id) ||
          []),
      ])

      setMoreResults(storyComplianceReport.data.data.moreResults)
    }

    if (storyComplianceReport.data?.data.moreResults) {
      setStoryReportPayload((prev) => ({
        ...prev,
        offset: prev.offset + 100,
      }))
    }
  }, [storyComplianceReport.data])

  return (
    <>
      <FilterModal
        isModalOpen={isFilterModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form={form}
      />

      <div
        style={{
          display: "flex",
          gap: 5,
          marginBottom: 10,
        }}
      >
        <Input
          id="search"
          placeholder="Type to search..."
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <Button onClick={showFilterModal}>
          <FilterOutlined />
        </Button>

        <Button
          onClick={() => {
            setReportData([])
            setSelectedRowKeys([])
            storyComplianceReport.refetch()
          }}
          loading={storyComplianceReport.isFetching}
          type="primary"
        >
          Search
        </Button>

        <ExportButton
          reportData={reportData}
          selectedReportData={selectedRowKeys}
          impartialResults={storyComplianceReport.isFetching || moreResults}
        />
      </div>

      <Table
        rowKey={(record) => record.id}
        style={{ marginBottom: 20 }}
        dataSource={
          reportData.sort((a, b) => {
            const aTitle = a.name.toLowerCase()
            const bTitle = b.name.toLowerCase()

            const query = searchQuery.toLowerCase()

            const aDistance = aTitle.includes(query) ? 0 : 1
            const bDistance = bTitle.includes(query) ? 0 : 1

            return aDistance - bDistance || aTitle.localeCompare(bTitle)
          }) ?? []
        }
        rowSelection={{
          selectedRowKeys,
          onChange: (keys: React.Key[]) => {
            setSelectedRowKeys(keys)
          },
        }}
      >
        <Table.Column title="Story ID" dataIndex="id" width={100} />
        <Table.Column title="Title" dataIndex="name" />
        <Table.Column
          title="Main Developer"
          dataIndex="mainDeveloper"
          width={200}
          render={(mainDeveloper: MainDeveloper[]) => {
            if (mainDeveloper == null) return "N/A"
            return mainDeveloper[0].name || mainDeveloper[0].developer || "N/A"
          }}
        />
        <Table.Column
          title="Labels"
          dataIndex="storyLabels"
          width={200}
          render={(label) => {
            if (label === null) return null
            return (
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  flexWrap: "wrap",
                }}
              >
                {label.map((label: Label) => (
                  <Tag color={label.color} style={{ color: "black" }}>
                    {label.name}
                  </Tag>
                ))}
              </div>
            )
          }}
        />
        <Table.Column
          title="State Time (m)"
          dataIndex="stateTime"
          width={250}
          render={(stateTime) => {
            if (stateTime == null) return null
            return (
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                {Object.entries(stateTime).map(([state, time]) => (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {state === "Total" ? (
                      <Divider style={{ margin: 0 }} />
                    ) : null}
                    <Col>{state === "Total" ? <b>{state}</b> : state}</Col>

                    <Col>{String(time)}</Col>
                  </Row>
                ))}
              </Row>
            )
          }}
        />
      </Table>
    </>
  )
}
