import { useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAuthStore } from "../stores/AuthStore"

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { env, isAuthenticated, updateAuthStatus, logout } = useAuthStore()
  const navigate = useNavigate()
  const location = useLocation()
  const hasCleared = useRef(false)

  useEffect(() => {
    updateAuthStatus()
  }, [env, updateAuthStatus])

  useEffect(() => {
    const handleUnauthenticated = async () => {
      if (!isAuthenticated[env] && !hasCleared.current) {
        hasCleared.current = true
        try {
          await logout(env)
          navigate("/login", { state: { from: location } })
        } catch (error) {
          console.error("Logout error:", error)
        }
      }
    }

    handleUnauthenticated()
  }, [env, isAuthenticated, logout, location, navigate])

  // Reset the flag when the user becomes authenticated
  useEffect(() => {
    if (isAuthenticated[env]) {
      hasCleared.current = false
    }
  }, [env, isAuthenticated])

  return <>{children}</>
}
