import { LoadingOutlined } from "@ant-design/icons"
import { JsonViewer, NamedColorspace } from "@textea/json-viewer"
import { DraggableModal } from "ant-design-draggable-modal"
import { Button, Input } from "antd"
import { useEffect, useState } from "react"
import { useAuthStore } from "../../../stores/AuthStore"
import { useWindowDimensions } from "../../../utils/hooks/useWindowDimensions"
import "../SelectedNodeModal/SelectedNodeModal.scss"

type SelectedNodeModalProps = {
  nodeID: string
  teamID: string
  team: string
  isOpen: boolean
  onCancel: () => void
  onClose: () => void
  onFocus: () => void
  nodeDataList: any
}

export default function SelectedNodeModal({
  nodeID,
  teamID,
  team,
  isOpen,
  onCancel,
  onClose,
  onFocus,
  nodeDataList,
}: SelectedNodeModalProps) {
  const { theme } = useAuthStore()
  const { height } = useWindowDimensions()

  const [searchQuery, setSearchQuery] = useState<string>("")

  const vizznLight: NamedColorspace = {
    scheme: "VizznLight",
    author: "Liam Kienitz",
    base00: "#fff",
    base01: "#6c6c6c",
    base02: "#c4c4c4",
    base03: "#f6f6f6",
    base04: "#c4c4c4",
    base05: "#e5ebff",
    base06: "#e8e8e8",
    base07: "#262f3d",
    base08: "#f44336",
    base09: "#424bd1",
    base0A: "#dbcc46",
    base0B: "#4caf50",
    base0C: "#19b1b4",
    base0D: "#2196f3",
    base0E: "#d14242",
    base0F: "#19b1b4",
  }

  const vizznDark: NamedColorspace = {
    scheme: "VizznDark",
    author: "Liam Kienitz",
    base00: "#2b2b4c",
    base01: "#6c6c6c",
    base02: "#6c6c6c",
    base03: "#f6f6f6",
    base04: "#c4c4c4",
    base05: "#e5ebff",
    base06: "#e8e8e8",
    base07: "#fafafa",
    base08: "#f44336",
    base09: "#2196f3",
    base0A: "#dbcc46",
    base0B: "#4caf50",
    base0C: "#19b1b4",
    base0D: "#2196f3",
    base0E: "#d14242",
    base0F: "#19b1b4",
  }

  const [nodeData, setNodeData] = useState<any>()

  useEffect(() => {
    let data = nodeDataList.find((data: any) => data.id === nodeID)
    if (data) {
      let sortedData: any = {}
      Object.keys(data)
        .sort((a, b) => {
          const aIncludes = a.includes(searchQuery)
          const bIncludes = b.includes(searchQuery)

          if (aIncludes !== bIncludes) {
            return Number(bIncludes) - Number(aIncludes)
          }

          return a.localeCompare(b)
        })

        .forEach((key) => {
          sortedData[key] = data[key]
        })
      setNodeData(sortedData)
    }
  }, [searchQuery, nodeDataList, nodeID])

  return (
    <DraggableModal
      key={nodeID + teamID}
      open={isOpen}
      title={
        <div>
          <h2 style={{ margin: 0 }}>{team}</h2>
          <p>{nodeID}</p>
        </div>
      }
      onCancel={onCancel}
      initialHeight={height * 0.7}
      zIndex={1000}
      footer={[
        <Button type="primary" danger onClick={onClose}>
          Close
        </Button>,
        <Button type="primary" onClick={onFocus}>
          Focus
        </Button>,
      ]}
    >
      {nodeDataList.find((data: any) => data.id === nodeID) ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Input
            placeholder="Type to search..."
            onChange={(event) => setSearchQuery(event.target.value)}
          ></Input>
          <div>
            <JsonViewer
              value={nodeData}
              defaultInspectDepth={3}
              theme={theme === "dark" ? vizznDark : vizznLight}
              style={{
                overflowY: "auto",
                paddingRight: 20,
                maxHeight: height * 0.6,
                scrollbarColor:
                  theme === "dark" ? "#e5ebff #2b2b4c" : "#24243f #fff",
              }}
            />
          </div>
        </div>
      ) : (
        <LoadingOutlined spin />
      )}
    </DraggableModal>
  )
}
