import { Layout } from "antd"
import logo from "../../assets/VIZZN_Logo_light.png"
import { AppLoginStatus } from "../../widgets/AppLoginStatus"
import DebugSelectMenu from "../Debug/DebugSelectMenu"
import { ThemeSwitcher } from "../Utils/ThemeSwitcher"

export const LayoutHeader = () => {
  return (
    <Layout.Header
      style={{
        position: "fixed",
        top: 0,
        width: "100vw",
        zIndex: 200,
        height: 99,
        color: "white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <img src={logo} alt="vizzn logo" style={{ width: 32 }} />
        <h1 style={{ marginRight: "-128px" }}>
          <span style={{ fontWeight: "600" }}>VIZZN </span>
          <span style={{ color: "#505AF1", fontWeight: "800" }}>ADMIN</span>
        </h1>
        <div>
          <span
            style={{
              paddingRight: "16px",
              fontWeight: "800",
            }}
          >
            v{require("../../../package.json").version}
          </span>
          {window.location.hostname === "localhost" && (
            <>
              <DebugSelectMenu />
            </>
          )}
          <AppLoginStatus />
          <ThemeSwitcher />
        </div>
      </div>
    </Layout.Header>
  )
}
