import { useState, useEffect, Key } from "react"
import { Modal, Dropdown, MenuProps } from "antd"
import { useAuthContext } from "../../context/AuthContext"
import { useAddVizznStoryComplianceReportToSheet } from "../../apis/ShortcutCompliance"
import { Report } from "../../types/ShortcutCompliance"

type Props = {
  reportData: Report[]
  selectedReportData: Key[]
  impartialResults: boolean
}

export default function ExportButton({
  reportData,
  selectedReportData,
  impartialResults,
}: Props) {
  const { env, token } = useAuthContext()
  const [sheetValues, setSheetValues] = useState<(string | Number)[][]>([])
  const [isModalVisible, setIsModalVisible] = useState(false)

  const addToSheet = useAddVizznStoryComplianceReportToSheet({
    env,
    token: token[env],
    payload: { sheetName: "Reports", values: sheetValues },
  })

  useEffect(() => {
    const sheetData = reportData
      .filter((item: Report) => selectedReportData.includes(item.id))
      .map((report) => {
        let developer = "N/A"
        if (report.mainDeveloper !== null) {
          developer =
            report.mainDeveloper[0].name ||
            report.mainDeveloper[0].developer ||
            "N/A"
        }

        let labels = ""
        if (report.labels !== null) {
          labels = report.labels.join(", ")
        }

        return [
          developer,
          report.id,
          labels,
          report.stateTime["In Planning"] || 0,
          report.stateTime["In Development"] || 0,
          report.stateTime["In Review"] || 0,
          Number(report.stateTime["In Planning"] || 0) +
            Number(report.stateTime["In Development"] || 0) +
            Number(report.stateTime["In Review"] || 0) || 0,
        ]
      })

    setSheetValues(sheetData)
  }, [reportData, selectedReportData])

  const onMenuClick: MenuProps["onClick"] = () => {
    window.open(
      "https://docs.google.com/spreadsheets/d/1zefOxuArjuylnA6eIPvx3oIbdMLcFwNvLZaaaVgf1Ho/edit?usp=sharing",
      "_blank"
    )
  }

  const handleExportClick = () => {
    if (!impartialResults) {
      addToSheet.refetch()
    } else {
      setIsModalVisible(true)
    }
  }

  const handleOk = () => {
    addToSheet.refetch()
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <Dropdown.Button
        menu={{
          items: [{ key: "open", label: "Open Sheet" }],
          onClick: onMenuClick,
        }}
        onClick={handleExportClick}
        type="primary"
        loading={addToSheet.isFetching}
        style={{ width: "auto" }}
        disabled={selectedReportData.length === 0}
      >
        Export {selectedReportData.length}/{reportData.length}
      </Dropdown.Button>
      <Modal
        title={"Warning"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Export"
        cancelText="Cancel"
      >
        <p>
          Not all results have been retrieved. Exporting the report may result
          in incomplete data.
        </p>
      </Modal>
    </>
  )
}
