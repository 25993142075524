import { Col, Row, Skeleton, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useGetEnterprises } from "../../apis/Enterprise/getEnterprises"
import ErrorRefetch from "../../components/ErrorRefetch/ErrorRefetch"
import GenericTableComponent from "../../components/GenericTableComponent/GenericTableComponent"
import { useAuthContext } from "../../context/AuthContext"
import { Enterprise } from "../../types/Enterprise"
import { useAuthStore } from "../../stores/AuthStore"

export default function EnterpriseListPage() {
  const navigate = useNavigate()
  const { env } = useAuthContext()
  const { token } = useAuthStore()
  const enterprises = useGetEnterprises({ env, token: token[env] })

  const columns: ColumnsType<Enterprise> = useMemo(
    () => [
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>ID</Typography.Text>
          </Row>
        ),
        dataIndex: "id",
        render: (value) => (
          <Row justify="center">
            <Col>{value}</Col>
          </Row>
        ),
        width: 130,
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Name</Typography.Text>
          </Row>
        ),
        dataIndex: "name",
        render: (value) => (
          <Row justify="center">
            <Col>{value}</Col>
          </Row>
        ),
      },
      {
        title: () => (
          <Row justify="center">
            <Typography.Text>Organization Count</Typography.Text>
          </Row>
        ),
        dataIndex: "childrenCount",
        render: (value) => (
          <Row justify="center">
            <Col>{value}</Col>
          </Row>
        ),
      },
    ],
    []
  )

  const onRowClick = useCallback(
    (record: Enterprise) => {
      return {
        onClick: () => navigate(`/enterprises/${record.id}`),
      }
    },
    [navigate]
  )

  if (enterprises.isLoading) return <Skeleton />
  if (enterprises.isError) return <ErrorRefetch refetch={enterprises.refetch} />

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <GenericTableComponent<Enterprise>
        data={enterprises.data ?? []}
        columns={columns}
        onRow={onRowClick}
        searchFilter={["name", "id"]}
      />
    </div>
  )
}
