import { debounce } from "lodash"

export const infiniteScroll = debounce(
  (
    event: any,
    fetchNextPage: () => void,
    direction: "up" | "down" = "down"
  ) => {
    if (event.target) {
      let maxScroll = event?.target?.scrollHeight - event.target.clientHeight
      let currentScroll = event.target.scrollTop
      let scrollThreshold = maxScroll - 100

      // Handle scrolling down
      if (
        direction === "down" &&
        currentScroll > scrollThreshold &&
        !infiniteScroll.isFetching
      ) {
        infiniteScroll.isFetching = true
        Promise.resolve(fetchNextPage()).finally(() => {
          infiniteScroll.isFetching = false
        })
      }

      // Handle scrolling up
      if (
        direction === "up" &&
        currentScroll === 0 &&
        !infiniteScroll.isFetching
      ) {
        infiniteScroll.isFetching = true
        Promise.resolve(fetchNextPage()).finally(() => {
          infiniteScroll.isFetching = false
        })
      }
    }
  },
  250,
  { leading: false, trailing: true }
) as any & { isFetching?: boolean }

// Initialize fetching flag
infiniteScroll.isFetching = false
