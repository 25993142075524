import { ActivityFeedsPropData } from "../../../types/DispatchExplorer"
import { ReactComponent as EquipmentIcon } from "../../../assets/sidenav-icons/equipment.svg"
import { ReactComponent as TruckingIcon } from "../../../assets/sidenav-icons/trucking.svg"
import { ReactComponent as SiteIcon } from "../../../assets/sidenav-icons/job_sites.svg"
import { ReactComponent as ExternalSiteIcon } from "../../../assets/sidenav-icons/external_sites.svg"
import { ReactComponent as PartnerIcon } from "../../../assets/sidenav-icons/partners.svg"
import { useAuthContext } from "../../../context/AuthContext"

const entityType = {
  equipment: {
    path: "/equipment/",
    icon: <EquipmentIcon className="activity-feed-icon" />,
  },
  contact: {
    path: "/contacts/",
    icon: <PartnerIcon className="activity-feed-icon" />,
  },
  site: { path: "/sites/", icon: <SiteIcon className="activity-feed-icon" /> },
  "dispatch-request": { path: "/dispatch-requests/", icon: <></> },
  dispatch: { path: "/dispatches/", icon: <></> },
  trailer: { path: "/trailers/", icon: <></> },
  truck: {
    path: "/trucks/",
    icon: <TruckingIcon className="activity-feed-icon" />,
  },
  "truck-configuration": { path: "/truck-configurations/", icon: <></> },
  "trailer-combination": { path: "/trailer-combinations/", icon: <></> },
  "external-company": {
    path: "/external-companies/",
    icon: <ExternalSiteIcon className="activity-feed-icon" />,
  },
  "dispatch-resource": { path: "/dispatch-resources/", icon: <></> },
}

export const EntityActivityFeed = ({
  type,
  data,
}: {
  type:
    | "equipment"
    | "site"
    | "dispatch-request"
    | "trailer"
    | "truck"
    | "truck-configuration"
    | "trailer-combination"
    | "dispatch"
    | "contact"
    | "external-company"
    | "dispatch-resource"
  data: ActivityFeedsPropData
}) => {
  const { env } = useAuthContext()
  return (
    <>
      <a
        className="markdown-link"
        href={`${
          env === "dev" ? "https://app.vizzn-dev.ca/" : "https://app.vizzn.ca/"
        }${entityType[type].path}${data.id}`}
      >
        {data ? String(data.value) : "empty"}
      </a>
    </>
  )
}
