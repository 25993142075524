import {
  CheckOutlined,
  CheckSquareFilled,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons"
import {
  Button,
  Checkbox,
  Col,
  Input,
  Popconfirm,
  Popover,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd"
import { ColumnsType } from "antd/lib/table"
import { useState } from "react"
import {
  useDeleteBucket,
  useDeleteProperty,
  useGetBuckets,
  useGetProperties,
  useUpdateProperty,
} from "../apis/notifications"
import { NewBucketModal } from "../components/Notification/Bucket/NewBucketModal"
import { UpdateBucketModal } from "../components/Notification/Bucket/UpdateBucketModal"
import { NewPropertyModal } from "../components/Notification/Property/NewPropertyModal"
import { UpdatePropertyModal } from "../components/Notification/Property/UpdatePropertyModal"
import { entityType } from "../components/Notification/constant"
import { useAuthContext } from "../context/AuthContext"
import {
  NotificationEntityBucket,
  NotificationEntityProperty,
} from "../types/notifications"

export const NotificationPropertyBucket = () => {
  const { env, token } = useAuthContext()
  const { mutateAsync: removeProperty } = useDeleteProperty()
  const { mutateAsync: removeBucket } = useDeleteBucket()
  const [editingProperty, setEditingProperty] =
    useState<NotificationEntityProperty>()
  const [editingBucket, setEditingBucket] = useState<NotificationEntityBucket>()
  const [devBucketId, setDevBucketId] = useState<string>("")
  const [entityFilter, setEntityFilter] = useState<string[]>([])
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [hasBucket, setHasBucket] = useState<boolean>(false)
  const [isPublished, setIsPublished] = useState<boolean>(false)
  const [hasProperties, setHasProperties] = useState<boolean>(false)
  const [searchValueBuckets, setSearchValueBuckets] = useState<string>("")
  const [searchValueProperties, setSearchValueProperties] = useState<string>("")
  const { mutateAsync: updateProperty } = useUpdateProperty()

  const notificationProperties = useGetProperties({
    env,
    token: token[env],
  })
  const notificationBuckets = useGetBuckets({
    env,
    token: token[env],
  })
  const notificationBucketsDev = useGetBuckets({
    env: "dev",
    token: token["dev"],
  })
  const propertyIdMap =
    notificationProperties.data?.reduce((obj, arr) => {
      obj[arr.id] = arr
      return obj
    }, {} as Record<string, NotificationEntityProperty>) || {}
  const entityTypeMapping: Record<string, string> = entityType.reduce(
    (obj, arr) => {
      obj[arr.id] = arr.name
      return obj
    },
    {} as Record<string, string>
  )

  const propertyColumns: ColumnsType<NotificationEntityProperty> = [
    {
      title: "Entity",
      render: (_, e) => entityTypeMapping[e.entityType] || e.entityType,
      key: "entityType",
      sorter: (a, b) => {
        if (!a.entityType) return 1
        if (!b.entityType) return -1
        return a.entityType < b.entityType ? -1 : 1
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Property",
      render: (_, e) => e.id,
      sorter: (a, b) => {
        if (!a.id) return 1
        if (!b.id) return -1
        return a.id.toLocaleLowerCase() < b.id.toLocaleLowerCase() ? -1 : 1
      },
      defaultSortOrder: "ascend",
      key: "id",
    },
    {
      title: "Completed",
      render: (_, e) => (
        <>
          <Checkbox
            defaultChecked={e.completed}
            onChange={(noti) => {
              updateProperty({
                payload: { ...e, completed: noti.target.checked },
                env: "dev",
                token: token.dev,
              })
              updateProperty({
                payload: { ...e, completed: noti.target.checked },
                env: "prod",
                token: token.prod,
              })
            }}
          ></Checkbox>
        </>
      ),

      key: "completed",
    },
    {
      title: "Note",
      ellipsis: true,
      render: (_, e) => e.description,
      key: "description",
    },
    {
      title: "Buckets",
      width: "25%",
      ellipsis: true,
      render: (_, e) =>
        e.buckets?.map((bucket) => (
          <Tag
            icon={bucket.isPublished && <CheckSquareFilled />}
            key={bucket.name}
          >
            {bucket.name}
          </Tag>
        )),
      key: "buckets",
    },
    {
      title: "Action",
      ellipsis: true,
      render: (_, e) => (
        <>
          <Button
            type="primary"
            onClick={() => setEditingProperty(e)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
          &nbsp;
          {!e.buckets?.length && (
            <Tooltip title="Remove Property">
              <Popconfirm
                title="Are you sure about removing this property? You need to be logged in dev and prod to do this"
                onConfirm={async () => {
                  if (!token.dev) {
                    return message.error("Please log in on Dev")
                  }
                  if (!token.prod) {
                    return message.error("Please log in on Prod")
                  }
                  await Promise.allSettled([
                    removeProperty({
                      id: e.id,
                      env: "dev",
                      token: token.dev,
                    }),
                    removeProperty({
                      id: e.id,
                      env: "prod",
                      token: token.prod,
                    }),
                  ])
                }}
                okText={"Yes"}
                cancelText={"No"}
              >
                <Button type="primary" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </>
      ),
      key: "action",
    },
  ]
  const bucketColumns: ColumnsType<NotificationEntityBucket> = [
    {
      title: "Entity Name",
      render: (_, e) => entityTypeMapping[e.entityType] || e.entityType,
      sorter: (a, b) => {
        if (!a.entityType) return 1
        if (!b.entityType) return -1
        return a.entityType < b.entityType ? -1 : 1
      },
      defaultSortOrder: "ascend",
      key: "entityType",
    },
    {
      title: "Bucket",
      render: (_, e) => (
        <>
          {e.name} {e.isPublished && <CheckSquareFilled />}
        </>
      ),
      sorter: (a, b) => {
        if (!a.name) return 1
        if (!b.name) return -1
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
      },
      defaultSortOrder: "ascend",
      key: "name",
    },
    {
      title: "Description",
      ellipsis: true,
      render: (_, e) => e.description,
      key: "description",
    },
    {
      title: "Properties",
      render: (_, e) =>
        e.properties.map((property) => (
          <Tag
            icon={
              !propertyIdMap[property.id].completed && (
                <CloseOutlined style={{ color: "red" }} />
              )
            }
          >
            {property.id}
          </Tag>
        )),
      key: "properties",
    },
    {
      title: "Action",
      ellipsis: true,
      render: (_, e) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              setEditingBucket(e)
              //I need this to get the dev bucket id and be able to edit both from prod
              setDevBucketId(
                notificationBucketsDev.data?.find(
                  (bucket) =>
                    bucket.name === e.name && bucket.entityType === e.entityType
                )?.id || ""
              )
            }}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
          &nbsp;
          {/*   {!e.properties.length && ()} */}
          <Tooltip title="Remove Bucket">
            <Popconfirm
              title="Are you sure about removing this bucket?"
              onConfirm={() => {
                removeBucket({
                  id: e.id,
                  env,
                  token: token[env],
                })
                if (env === "prod") {
                  const devBucketId =
                    notificationBucketsDev.data?.find(
                      (bucket) =>
                        bucket.name === e.name &&
                        bucket.entityType === e.entityType
                    )?.id || ""
                  if (devBucketId)
                    removeBucket({
                      id: devBucketId,
                      env: "dev",
                      token: token["dev"],
                    })
                }
                message.success("Bucket Removed")
              }}
              okText={"Yes"}
              cancelText={"No"}
            >
              <Button type="primary" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
      key: "action",
    },
  ]

  return (
    <Tabs>
      <Tabs.TabPane tab="Notifiable Properties" key="properties">
        <Row>
          <Col flex="auto">
            <div style={{ display: "flex" }}>
              <Popover
                content={
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      Completed
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        onChange={(checked) => setIsCompleted(checked)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Has Bucket
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        onChange={(checked) => setHasBucket(checked)}
                      />
                    </div>
                  </div>
                }
                title="Extra Filters"
                trigger="click"
              >
                <Button
                  style={{ width: "50px" }}
                  icon={<FilterOutlined />}
                ></Button>
              </Popover>
              <Select
                value={entityFilter}
                mode="multiple"
                style={{ width: "500px" }}
                onChange={setEntityFilter}
                placeholder="Select Entity Type"
              >
                {entityType.map((entity) => (
                  <Select.Option key={entity.id} value={entity.id}>
                    {entity.name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                value={searchValueProperties}
                onChange={(e) => setSearchValueProperties(e.target.value)}
                placeholder={"Search Property"}
                addonAfter={<SearchOutlined />}
              />
            </div>
          </Col>
          <Col>
            <NewPropertyModal />
          </Col>
        </Row>
        <Row>
          <Table
            columns={propertyColumns}
            rowKey="id"
            scroll={{ y: "calc(100vh - 310px)" }}
            dataSource={
              notificationProperties.data?.filter(
                (property) =>
                  (entityFilter.length === 0 ||
                    entityFilter.includes(property.entityType)) &&
                  (searchValueProperties.length === 0 ||
                    property.id
                      .toLowerCase()
                      .includes(searchValueProperties.toLowerCase())) &&
                  (isCompleted ? property.completed : entityFilter) &&
                  (hasBucket ? property.buckets?.length : entityFilter)
              ) || []
            }
            pagination={false}
          />
          {editingProperty && (
            <UpdatePropertyModal
              isVisible={true}
              property={editingProperty}
              setVisible={setEditingProperty}
            />
          )}
        </Row>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Notifiable Buckets" key="buckets">
        <Row>
          <Col flex="auto">
            <div style={{ display: "flex" }}>
              <Popover
                content={
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      Published
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        onChange={(checked) => setIsPublished(checked)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Has Properties
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        onChange={(checked) => setHasProperties(checked)}
                      />
                    </div>
                  </div>
                }
                title="Extra Filters"
                trigger="click"
              >
                <Button
                  style={{ width: "50px" }}
                  icon={<FilterOutlined />}
                ></Button>
              </Popover>
              <Select
                value={entityFilter}
                mode="multiple"
                style={{ width: "500px" }}
                onChange={setEntityFilter}
                placeholder="Select Entity Type"
              >
                {entityType.map((entity) => (
                  <Select.Option key={entity.id} value={entity.id}>
                    {entity.name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                value={searchValueBuckets}
                onChange={(e) => setSearchValueBuckets(e.target.value)}
                placeholder={"Search Bucket"}
                addonAfter={<SearchOutlined />}
              />
            </div>
          </Col>

          <Col>
            <NewBucketModal />
          </Col>
        </Row>
        <Row>
          <Table
            columns={bucketColumns}
            rowKey="id"
            scroll={{ y: "calc(100vh - 310px)" }}
            dataSource={
              notificationBuckets.data?.filter(
                (bucket) =>
                  (entityFilter.length === 0 ||
                    entityFilter.includes(bucket.entityType)) &&
                  (searchValueBuckets.length === 0 ||
                    bucket.name
                      .toLowerCase()
                      .includes(searchValueBuckets.toLowerCase())) &&
                  (isPublished ? bucket.isPublished : entityFilter) &&
                  (hasProperties ? bucket.properties.length : entityFilter)
              ) || []
            }
            pagination={false}
          />
          {editingBucket && (
            <UpdateBucketModal
              isVisible={true}
              bucket={editingBucket}
              devBucketId={devBucketId}
              setVisible={setEditingBucket}
              entityProperties={
                notificationProperties.data
                  ?.filter(
                    (property) =>
                      property.entityType === editingBucket.entityType
                  )
                  .map((property) => property.id) || []
              }
              notificationProperties={notificationProperties.data || []}
            />
          )}
        </Row>
      </Tabs.TabPane>
    </Tabs>
  )
}
