import {
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons"
import { Tooltip } from "antd"
import Markdown from "react-markdown"
import { ActivityFeedData } from "../../../../types/DispatchEvent"
import {
  ArchivedIcon,
  SystemAdminIcon,
  TimeIcon,
  UnarchivedIcon,
  UserIcon,
} from "../../../VizznIcons"
import "./ActivityFeedMarkDown.scss"
import { MarkDownMessage, markdownRouter } from "./MarkDownMessage"
import DeleteActivityFeedMessageWidget from "./widget/DeleteActivityFeedMessageWidget"
import { dateDisplay } from "../../../Team/partials/dateDisplay"

const getIcon = (val: boolean | string) => {
  switch (val) {
    case "archived":
      return <ArchivedIcon className="circle-icon archive-icon" />
    case "unarchived":
      return <UnarchivedIcon className="circle-icon unarchive-icon" />
    case "created":
      return <PlusOutlined className="circle-icon plus-icon" />
    case "deleted":
      return <CloseOutlined className="circle-icon minus-icon" />
    case "completed":
      return <CheckOutlined className="circle-icon completed-icon" />
    case "started":
      return <CaretRightOutlined className="circle-icon start-icon" />
    case "updated":
    default:
      return <EditFilled className="circle-icon" />
  }
}

export const ActivityFeedMessage = ({
  message,
}: {
  message: ActivityFeedData
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div className="drawer-icons">{getIcon(message.type)}</div>
        <DeleteActivityFeedMessageWidget id={message.id} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <MarkDownMessage message={message} />

        {!!message.subTexts &&
          message.subTexts.length > 0 &&
          message.subTexts.map((text) => (
            <div key={text} style={{ marginLeft: 16 }}>
              <small>
                <Markdown
                  className="markdown"
                  components={markdownRouter(message) as any}
                >
                  {text}
                </Markdown>
              </small>
              <hr />
            </div>
          ))}

        <div className="light-grey">
          <div>
            {message.createdBy.isSysAdmin ? (
              <div style={{ display: "flex", width: "max-content" }}>
                <Tooltip title={"System Admin"}>
                  <SystemAdminIcon />
                </Tooltip>
                {message.createdBy.name}
              </div>
            ) : (
              <>
                <UserIcon /> {message.createdBy.name}
              </>
            )}
          </div>
          <div>
            <TimeIcon /> {dateDisplay(message.createdAt, "fullDateAndHms")}
          </div>
        </div>
      </div>
    </div>
  )
}
