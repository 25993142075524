import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  NotificationSubscriptionBucket,
  NotificationUserBucketPreference,
  SubscribablesGroupV2,
  Subscription,
  SubscriptionV2,
} from "../types/Subscription"
import Axios, { AxiosRequestConfig } from "axios"
import { VizznResponse } from "../types/AccountApplication"
import { environmentType } from "../types/environtment"
import { message } from "antd"
import { TokenState, VizznUser } from "../types/user"
import { getEnvUrl } from "../utils/utils"
import { SubsribablesGroup } from "../types/List"

const getSubscriptions = async (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  const { data } = await Axios.get<VizznResponse<Subscription[]>>(
    `${getEnvUrl(body.env)}/v1/subscriptions`,
    {
      params: { userId: body.id },
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
      ...body.config,
    }
  )
  return data.data
}

export const useSubscriptions = (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  const subscriptions = useQuery<Subscription[]>(
    ["subscriptions", body.id, body.env],
    () => getSubscriptions(body),
    {
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
    }
  )

  return subscriptions
}

const getSubscriptionsV2 = async (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  const { data } = await Axios.get<VizznResponse<SubscriptionV2[]>>(
    `${getEnvUrl(body.env)}/v2/user-notifiable-subscription`,
    {
      params: { userId: body.id },
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
      ...body.config,
    }
  )

  return data.data
}
export const useSubscriptionsV2 = (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  const subscriptions = useQuery<SubscriptionV2[]>(
    ["subscriptionsV2", body.id, body.env],
    () => getSubscriptionsV2(body),
    {
      staleTime: 3600 * 1000,
      cacheTime: 3600 * 1000,
    }
  )

  return subscriptions
}

const getBuckets = async (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  try {
    const { data } = await Axios.get<
      VizznResponse<NotificationSubscriptionBucket[]>
    >(`${getEnvUrl(body.env)}/v1/notifiable/bucket?isPublished=true`, {
      headers: {
        Authorization: `Bearer ${body.token}`,
      },
      ...body.config,
    })

    if (!data) {
      console.error("No data received from the API")
      return undefined
    }

    return data.data
  } catch (error) {
    console.error("Error fetching buckets:", error)
    return undefined
  }
}

export const useBuckets = (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  const response = useQuery<NotificationSubscriptionBucket[] | undefined>(
    ["notifiable-buckets", body.env, body.id],
    () => getBuckets(body)
  )

  return response
}
const getUserBucketPreferences = async (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  try {
    const { data } = await Axios.get<
      VizznResponse<NotificationUserBucketPreference[]>
    >(
      `${getEnvUrl(body.env)}/v2/user-notifiable-bucket-preference?userId=${
        body.id
      }`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )

    return data.data
  } catch (error) {
    console.error("Error fetching user bucket preferences:", error)
    return undefined
  }
}
export const useUserBucketPreferences = (body: {
  env: environmentType
  token: string | null
  id: string
  config?: AxiosRequestConfig
}) => {
  const response = useQuery<NotificationUserBucketPreference[] | undefined>(
    ["user-notifiable-bucket-preferences"],
    () => getUserBucketPreferences(body)
  )

  return response
}
export const deleteUserSubscriptionListV2 = async (body: {
  subscriptionId: string
  id: string
  env: environmentType
  token: string | null
}) => {
  try {
    const response = await Axios.delete<
      VizznResponse<{ subscriptionId: string }>
    >(
      `${getEnvUrl(body.env)}/v2/user-notifiable-subscription/${
        body.subscriptionId
      }`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        data: { userId: body.subscriptionId, id: body.id },
      }
    )
    message.success(response.data.detail)
    return response.data.data
  } catch (error: any) {
    message.error(error.message)
  }
}
export const useDeleteSubscriptionV2 = (userId: string) => {
  const queryClient = useQueryClient()

  return useMutation(deleteUserSubscriptionListV2, {
    onSuccess: async (subscription) => {
      if (!subscription) return

      queryClient.setQueryData<SubscriptionV2[]>(
        ["subscriptionsV2", userId],
        (results) => {
          if (!results) return []

          return results.filter(
            (data) => data.id !== subscription.subscriptionId
          )
        }
      )
    },
  })
}

export const deleteUserSubscriptionList = async (body: {
  userId: string
  id: string
  env: environmentType
  token: string | null
}) => {
  try {
    const response = await Axios.delete<VizznResponse<Subscription[]>>(
      `${getEnvUrl(body.env)}/v1/users/${body.userId}/subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        data: { userId: body.userId, id: body.id },
      }
    )
    message.success(response.data.detail)
    return response.data.data
  } catch (error: any) {
    message.error(error.message)
  }
}

export const useDeleteSubscriptionList = (userId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(deleteUserSubscriptionList, {
    onSuccess: async (subscription, params) => {
      if (!subscription) return

      queryClient.setQueryData<Subscription[]>(
        ["subscriptions", userId, params.env],
        (results) => {
          if (!results) return []

          const deletedSub = subscription[0]
          return results.filter((data) => data.id !== deletedSub.id)
        }
      )
    },
  })
}

export const userSetPrimarySubscription = async (body: {
  isPrimary: boolean
  userId: string
  id: string
  env: environmentType
  token: string | null
}) => {
  try {
    const response = await Axios.put<VizznResponse<VizznUser>>(
      `${getEnvUrl(body.env)}/v1/users/${body.userId}/subscriptions/${
        body.id
      }/primary`,
      { userId: body.userId, id: body.id, isPrimary: body.isPrimary },
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(response.data.detail)
    return response.data.data
  } catch (error: any) {
    message.error(error.message)
  }
}

export const useSetPrimarySubscription = (userId: string) => {
  const queryClient = useQueryClient()

  return useMutation(userSetPrimarySubscription, {
    onSuccess: async (user, params) => {
      if (!user) return
      queryClient.refetchQueries(["subscriptions", userId, params.env])
    },
  })
}

export const notificationUserSubscription = async (req: {
  isNotification: boolean
  body: {
    userId: string
    id: string
    env: environmentType
    token: string | null
  }
}) => {
  const { body, isNotification } = req
  try {
    const response = await Axios.put<VizznResponse<any>>(
      `${getEnvUrl(body.env)}/v1/users/${body.userId}/subscriptions/${
        body.id
      }/notification`,
      {
        userId: body.userId,
        id: body.id,
        isNotification,
      },
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(response.data.detail)

    return response.data.data
  } catch (error: any) {
    message.error(error.message)
  }
}

export const useNotificationUserSubscription = (userId: string) => {
  const queryClient = useQueryClient()
  return useMutation(notificationUserSubscription, {
    onSuccess: async (user, params) => {
      if (!user) return
      queryClient.refetchQueries(["subscriptions", userId, params.body.env])
    },
  })
}

const updateUserSubscriptionV2 = async (body: {
  id: string
  isDefault: boolean
  feedBucketIds?: string[]
  notiBucketIds?: string[]
  env: environmentType
  token: string | null
}) => {
  try {
    const response = await Axios.put<VizznResponse<SubscriptionV2>>(
      `${getEnvUrl(body.env)}/v2/user-notifiable-subscription/${body.id}`,
      {
        id: body.id,
        isDefault: body.isDefault,
        feedBucketIds: body.feedBucketIds,
        notiBucketIds: body.notiBucketIds,
      },
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(response.data.detail)
    return response.data.data
  } catch (error: any) {
    message.error(error.message)
  }
}
export const useUpdateSubscriptionV2 = (userId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(updateUserSubscriptionV2, {
    onSuccess: async (subscription) => {
      if (!subscription) return

      queryClient.setQueryData<SubscriptionV2[]>(
        ["subscriptionsV2", userId],
        (results) => {
          if (!results) return []
          return [
            ...results.filter((data) => data.id !== subscription.id),
            subscription,
          ]
        }
      )
    },
  })
}

const updateUserBucketPreference = async (body: {
  bucketId: string
  feedBucketIds: string[]
  notiBucketIds: string[]
  env: environmentType
  token: TokenState
}) => {
  const response = await Axios.put<
    VizznResponse<NotificationUserBucketPreference>
  >(
    `${getEnvUrl(body.env)}/v2/user-notifiable-bucket-preference/${
      body.bucketId
    }`,
    {
      feedBucketIds: body.feedBucketIds,
      notiBucketIds: body.notiBucketIds,
    },
    {
      headers: {
        Authorization: `Bearer ${body.token[body.env]}`,
      },
    }
  )
  message.success(response.data.detail)
  return response.data.data
}

export const useUpdateUserBucketPreference = (userId: string) => {
  const queryClient = useQueryClient()

  return useMutation(updateUserBucketPreference, {
    onSuccess: async (pref) => {
      if (!pref) return
      queryClient.setQueryData<NotificationUserBucketPreference[]>(
        ["user-notifiable-bucket-preferences"],
        (results) => {
          if (!results) return []
          return [...results.filter((data) => data.id !== pref.id), pref]
        }
      )
      queryClient.setQueryData<SubscriptionV2[]>(
        ["subscriptionsV2", userId],
        (results) => {
          if (!results) return []
          return results.map((item) =>
            item.entityType === pref.entityType && item.isDefault
              ? {
                  ...item,
                  notiBuckets: pref.notiBuckets || [],
                  feedBuckets: pref.feedBuckets || [],
                }
              : item
          )
        }
      )
      /// ### update each subscription as well
      queryClient.invalidateQueries(["user-notifiable-bucket-preferences"])
    },
  })
}
export const createUserBucketPreference = async (body: {
  feedBucketIds: string[]
  notiBucketIds: string[]
  userId: string
  entityType: string
  env: environmentType
  token: TokenState
}) => {
  const response = await Axios.post<
    VizznResponse<NotificationUserBucketPreference[]>
  >(
    `${getEnvUrl(body.env)}/v2/user-notifiable-bucket-preference`,

    {
      feedBucketIds: body.feedBucketIds,
      notiBucketIds: body.notiBucketIds,
      userId: body.userId,
      entityType: body.entityType,
    },
    {
      headers: {
        Authorization: `Bearer ${body.token[body.env]}`,
      },
    }
  )
  message.success(response.data.detail)
  return response.data.data
}

export const useCreateUserBucketPreference = () => {
  const queryClient = useQueryClient()

  return useMutation(createUserBucketPreference, {
    onSuccess: async (pref) => {
      if (!pref) return
      queryClient.setQueryData<NotificationUserBucketPreference[]>(
        ["user-notifiable-bucket-preferences"],
        pref
      )
      queryClient.refetchQueries(["user-notifiable-bucket-preferences"])
    },
  })
}

export const createUserSubscription = async (body: {
  subscribedIds: string[]
  userId: string
  env: environmentType
  token: string | null
}): Promise<Subscription[] | undefined> => {
  try {
    const { data } = await Axios.post<VizznResponse<Subscription[]>>(
      `${getEnvUrl(body.env)}/v1/users/${body.userId}/subscriptions`,
      {
        userId: body.userId,
        subscribedIds: body.subscribedIds,
      },
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(data.detail)
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}
export const useCreatUserSubscription = (userId?: string) => {
  const queryClient = useQueryClient()
  return useMutation(createUserSubscription, {
    onSuccess: async (subscriptions, params) => {
      if (!subscriptions) return

      const oldData = queryClient.getQueryData<Subscription[]>([
        "subscriptions",
        userId,
        params.env,
      ])
      if (!oldData) return
      queryClient.setQueryData(
        ["subscriptions", userId, params.env],
        [...oldData, ...subscriptions]
      )
    },
  })
}

const createUserSubscriptionV2 = async (body: {
  entityId: string
  entityType?: string
  isDefault?: boolean
  userId: string
  notiBucketIds?: string[]
  feedBucketIds?: string[]
  env: environmentType
  token: string | null
}): Promise<SubscriptionV2[] | undefined> => {
  try {
    const { data } = await Axios.post<VizznResponse<SubscriptionV2[]>>(
      ` ${getEnvUrl(body.env)}/v2/user-notifiable-subscription`,
      {
        entityId: body.entityId,
        entityType: body.entityType,
        isDefault: body.isDefault,
        userId: body.userId,
        notiBucketIds: body.notiBucketIds,
        feedBucketIds: body.feedBucketIds,
      },
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    message.success(data.detail)
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useCreatUserSubscriptionV2 = (userId: string) => {
  const queryClient = useQueryClient()
  return useMutation(createUserSubscriptionV2, {
    onMutate: async (body) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["subscriptionsV2", userId])
      // Snapshot the previous value
      const previousValue = queryClient.getQueryData<SubscriptionV2[]>([
        "subscriptionsV2",
        userId,
      ])
      // Optimistically update to the new value
      // queryClient.setQueryData<SubscriptionV2[]>(['subscriptionsV2', userId], (results) => {
      //   if (!results) return []
      //   return setArrayDataCreate(
      //     body.subscribedIds.map((id) => ({
      //       id: `${body.userId}_${id}`,
      //       name: 'Loading...',
      //       quickview: false,
      //       isPushActive: false,
      //       isPrimary: false,
      //       roles: [],
      //       type: 'user',
      //       subscribedId: id,
      //     })),
      //     results
      //   )
      // })
      return previousValue
    },
    onError: (error, id, previousValue: any) => {
      queryClient.setQueryData<SubscriptionV2[]>(
        ["subscriptionsV2", userId],
        previousValue
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries(["subscriptionsV2", userId])
    },
  })
}
export const deleteUserSubscription = async (body: {
  userId: string
  id: string[]
  env: environmentType
  token: string | null
}) => {
  try {
    const response = await Axios.delete<VizznResponse<Subscription[]>>(
      `${getEnvUrl(body.env)}/v1/users/${body.userId}/subscriptions`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
        data: {
          userId: body.userId,
          id: body.id.map((id) => body.userId + "_" + id),
        },
      }
    )
    message.success(response.data.detail)
    return response.data.data
  } catch (error: any) {
    message.error(error.details)
  }
}

export const useDeleteUserSubscription = (userId?: string) => {
  const queryClient = useQueryClient()

  return useMutation(deleteUserSubscription, {
    onSuccess: async (subsriptions, params) => {
      if (!subsriptions) return
      const oldData = queryClient.getQueryData<Subscription[]>([
        "subscriptions",
        userId,
        params.env,
      ])
      if (!oldData) return
      const deletedIds = subsriptions.map((s) => s.id)
      queryClient.setQueriesData(
        ["subscriptions", userId, params.env],
        oldData.filter((s) => !deletedIds.includes(s.id))
      )
    },
  })
}

const getSubscribables = async (body: {
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await Axios.get<VizznResponse<SubsribablesGroup[]>>(
      `${getEnvUrl(body.env)}/v1/subscribables`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    return data.data
  } catch (error: any) {
    message.error(error.message)
  }
}

export const useSubscribables = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<SubsribablesGroup[] | undefined>(
    ["subscribables"],
    () => getSubscribables(body)
  )

  return response
}
const getSubscribablesV2 = async (body: {
  env: environmentType
  token: string | null
}) => {
  try {
    const { data } = await Axios.get<VizznResponse<SubscribablesGroupV2[]>>(
      `${getEnvUrl(body.env)}/v2/subscribables`,
      {
        headers: {
          Authorization: `Bearer ${body.token}`,
        },
      }
    )
    return data.data
  } catch (error) {
    // axios response error intercepted
  }
}

export const useSubscribablesV2 = (body: {
  env: environmentType
  token: string | null
}) => {
  const response = useQuery<SubscribablesGroupV2[] | undefined>(
    ["subscribablesV2"],
    () => getSubscribablesV2(body)
  )

  return response
}
export const useMySubscriptionsDataV2 = (body: {
  userId: string
  env: environmentType
  token: string | null
}) => {
  const subscriptions = useQuery<SubscriptionV2[]>(
    ["subscriptionsV2", body.userId],
    () =>
      getSubscriptionsV2({
        env: body.env,
        token: body.token,
        id: body.userId,
      })
  )

  return subscriptions
}
