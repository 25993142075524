import { faRssSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Divider, Drawer, Tooltip } from "antd"
import { useState } from "react"
import { usePaginatedActivityFeedCore } from "../../../../apis/ActivityFeed"
import "./ActivityFeedMarkDown.scss"
import { ActivityFeedMessage } from "./ActivityFeedMessage"
import { ListHeader } from "../../../Utils/ListHeader"
import { useSearchTags } from "../../../../utils/useSearchTags"
import { infiniteScroll } from "../../../Utils/infiniteScroll"

type Props = {
  id: string
}

export default function UserActivityFeedDrawerWidget({ id }: Props) {
  const [open, setOpen] = useState(false)
  const searchTerms = useSearchTags("activityFeedTags")
  const feed = usePaginatedActivityFeedCore(
    id,
    20,
    searchTerms.tags.length > 0 || searchTerms.searchValue
      ? [
          ...searchTerms.tags,
          ...(searchTerms.searchValue ? [searchTerms.searchValue] : []),
        ]
      : undefined
  )

  return (
    <>
      <Tooltip title="View User Activity Feed">
        <Button
          onClick={() => setOpen(true)}
          type="primary"
          icon={<FontAwesomeIcon icon={faRssSquare} />}
        />
      </Tooltip>
      <Drawer
        closable
        destroyOnClose
        title={<p>Activity Feed</p>}
        placement="right"
        open={open}
        width={800}
        onClose={() => setOpen(false)}
      >
        <div
          id="activityFeed"
          onScroll={(e) => {
            e.persist()
            infiniteScroll(e, feed.fetchNextPage, feed.hasNextPage)
          }}
        >
          <ListHeader total={0} displayedNum={0} searchTags={searchTerms} />
          <br />
          {feed.data?.pages.flat().map((page) => (
            <>
              <ActivityFeedMessage message={page} />
              <Divider />
            </>
          ))}
        </div>
      </Drawer>
    </>
  )
}
