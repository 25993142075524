import { green, red } from "@ant-design/colors"
import { TeamOutlined } from "@ant-design/icons"
import { Button, Col, Input, Row, Table, Tag, Tooltip, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetUsersV2 } from "../apis/user"
import { AdminIcon } from "../assets/AdminIcon"
import { CopyContent } from "../components/Utils/CopyContent"
import { useAuthContext } from "../context/AuthContext"
import { useAuthStore } from "../stores/AuthStore"
import { useDebounce } from "../hooks/useDebounce"
import { UserRecordV2 } from "../types/user"

export const UserSearch = () => {
  const { token, env } = useAuthContext()
  const { theme } = useAuthStore()
  const [searchTerm, setSearchTerm] = useState<string>("")
  const debouncedSearch = useDebounce(searchTerm, 400)
  const searchInputRef = useRef<any>(null)

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus()
    }
  }, [])

  const navigate = useNavigate()

  const users = useGetUsersV2(
    {
      env,
      token: token[env],
    },
    debouncedSearch
  )

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const isUserDeleted = (
    record: UserRecordV2,
    value?: string | JSX.Element
  ) => {
    return record.email.includes("deleted__") ? (
      <p style={{ color: red.primary }}>{value}</p>
    ) : (
      value
    )
  }
  const columns: ColumnsType<UserRecordV2> = [
    {
      title: "Name",
      render: (_, e) => (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {isUserDeleted(e, e.name)}
          {e.roleNames.includes("sysadmin") && (
            <Tooltip title="System Admin">
              <AdminIcon
                style={
                  theme === "light" ? { fill: "#000000" } : { fill: "#FFFFFF" }
                }
              />
            </Tooltip>
          )}
        </div>
      ),
      sorter: (a, b) => {
        if (!a.name) return 1
        if (!b.name) return -1
        return a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1
      },
      defaultSortOrder: "ascend",
      key: "name",
      onCell: (record) => {
        return { onClick: () => navigate(`/users/${record.id}`) }
      },
    },
    {
      title: "Team",
      render: (_, e) => (
        <Row gutter={5} wrap={false}>
          <Col>{isUserDeleted(e, e.team.name)}</Col>
          <Col>
            <CopyContent
              //@ts-ignore
              text={e.team.id}
              hideText
              tooltip={`Copy Team ID`}
            />
          </Col>
          <Col>
            <Tooltip title="Team Overview">
              <Button
                size="small"
                onClick={() => navigate(`/teams/${e.team.id}`)}
                //@ts-ignore
                icon={<TeamOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
      sorter: (a, b) => {
        if (!a.team.name) return 1
        if (!b.team.name) return -1
        return a.team.name.toLocaleLowerCase() < b.team.name.toLocaleLowerCase()
          ? -1
          : 1
      },
      defaultSortOrder: "ascend",
      key: "name",
    },
    {
      title: "Email",
      render: (_, e) =>
        isUserDeleted(
          e,
          <div style={{ display: "flex", gap: "5px" }}>
            {e.email}
            <CopyContent text={e.email} hideText tooltip="Copy User Email" />
          </div>
        ),
      sorter: (a, b) => {
        if (!a.email) return 1
        if (!b.email) return -1
        return a.email.toLocaleLowerCase() < b.email.toLocaleLowerCase()
          ? -1
          : 1
      },
      defaultSortOrder: "ascend",
      key: "email",
    },
    {
      title: "Id",

      render: (_, e) =>
        isUserDeleted(
          e,
          <div style={{ display: "flex", gap: "5px" }}>
            {e.id}
            <CopyContent text={e.id} hideText tooltip="Copy User ID" />
          </div>
        ),
      sorter: (a, b) => (a.id.toLowerCase() < b.id.toLowerCase() ? -1 : 1),
      key: "id",
    },
    {
      title: "Active",

      render: (_, e) => (
        <Row justify="center">
          <Tag
            style={{
              background: e.activeAccount ? green.primary : red.primary,
              color: "white",
            }}
          >
            {e.activeAccount ? "Yes" : "No"}
          </Tag>
        </Row>
      ),
      sorter: (a, b) => (a.activeAccount < b.activeAccount ? -1 : 1),
      key: "isActive",
    },
  ]

  return (
    <div style={{ marginBottom: "20px" }}>
      <Row gutter={0} style={{ marginBottom: 10 }}>
        <Col flex="auto">
          <Input
            ref={searchInputRef}
            placeholder="Type to search..."
            onChange={onSearchChange}
            style={{ width: "100%" }}
            defaultValue={searchTerm}
          />
        </Col>
      </Row>
      {users.data && (
        <Typography.Title
          level={4}
        >{`${users?.data?.totalRecords} results found`}</Typography.Title>
      )}
      <Row>
        <Table
          style={{ minWidth: "100%" }}
          columns={columns}
          rowKey={(record) => `${record.id}-${record.team.id}`}
          dataSource={users?.data?.records || []}
          loading={!!searchTerm && users.isLoading}
          pagination={false}
          rowClassName={(record, index) =>
            record.email.includes("deleted__") ? "table-row-deleted" : ""
          }
        />
      </Row>
    </div>
  )
}
