import { Modal, Form, Select, DatePicker, Button } from "antd"
import dayjs from "dayjs"
import { useAuthContext } from "../../context/AuthContext"
import { useGetVizznShortcutOwnersLabels } from "../../apis/ShortcutCompliance"
import { useEffect, useState } from "react"

const { RangePicker } = DatePicker
const { Option } = Select

type FilterModalProps = {
  isModalOpen: boolean
  handleOk: () => void
  handleCancel: () => void
  form: any
}

export default function FilterModal({
  isModalOpen,
  handleOk,
  handleCancel,
  form,
}: FilterModalProps) {
  const { env, token } = useAuthContext()
  const [initialValues, setInitialValues] = useState({})

  const ownersLabels = useGetVizznShortcutOwnersLabels({
    env,
    token: token[env],
    enabled: isModalOpen,
  })

  useEffect(() => {
    if (isModalOpen) {
      setInitialValues(form.getFieldsValue())
    }
  }, [isModalOpen, form])

  const handleReset = () => {
    form.setFieldsValue(initialValues)
    handleCancel()
  }

  const handleClear = () => {
    form.resetFields()
    form.setFieldsValue({
      dateRange: null,
    })
  }

  return (
    <Modal
      title="Filter"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleReset}
      closable
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button key="clear" onClick={handleClear}>
            Clear
          </Button>

          <div style={{ display: "flex", gap: 8 }}>
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>

            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Date Range"
          name="dateRange"
          initialValue={[dayjs().subtract(1, "month"), dayjs()]}
        >
          <RangePicker
            picker="month"
            key="dateRange"
            id="dateRange"
            allowClear
            format="YYYY-MM-DD"
            placeholder={["Start Date", "End Date"]}
          />
        </Form.Item>
        <Form.Item label="Owners" name="owners">
          <Select
            mode="multiple"
            allowClear
            placeholder="Select owners"
            key="owners"
          >
            {ownersLabels.data?.owners.map((owner) => (
              <Option key={owner.mentionName} value={owner.mentionName}>
                {owner.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Labels" name="labels">
          <Select mode="multiple" allowClear placeholder="Select labels">
            {ownersLabels.data?.labels.map((label) => (
              <Option key={label.name} value={label.name}>
                {label.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Story IDs" name="storyIds">
          <Select mode="tags" allowClear />
        </Form.Item>
      </Form>
    </Modal>
  )
}
