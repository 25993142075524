import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { decodeJwt } from "jose"
import { useAuthStore } from "../../stores/AuthStore"
import { VizznResponse } from "../../types/Api"
import { TokenState, UserState } from "../../types/user"
import { isTokenExpired } from "../../utils/isTokenExpired"
import { getEnvUrl } from "../../utils/utils"
import { environmentType } from "../../types/environtment"

const refreshWorkosToken = async (): Promise<boolean> => {
  const environments = ["dev", "prod"]
  const refreshResults = []

  for (const env of environments) {
    const result = await (async () => {
      const { token, user } = useAuthStore.getState()
      if (!token || !user) return false
      const unparsedToken = token
      const unparsedUser = user

      const currentToken: TokenState | null = unparsedToken
        ? unparsedToken
        : null
      const currentUser: UserState | null = unparsedUser ? unparsedUser : null

      const envToken = currentToken?.[env as keyof TokenState]
      const envUser = currentUser?.[env as keyof UserState]

      if (!envToken || !envUser) return false

      const decodedToken = decodeJwt(envToken!)
      const expirationTime = decodedToken.exp

      const authId = envUser.authId
      const userId = envUser.id

      if (!expirationTime) return false

      if (isTokenExpired(expirationTime)) {
        // refresh token
        const data = await axios.post<VizznResponse<{ data: string }>>(
          `${getEnvUrl(env as environmentType)}/workos/refresh-v2`,
          {},
          {
            headers: {
              Authorization: `Bearer ${envToken}`,
              "vizzn-auth-id": authId,
              "vizzn-user-id": userId,
            },
          }
        )

        const newState = {
          token: data.data.data,
        }

        const newTokenObject = {
          ...currentToken,
          [env]: newState.token,
        }

        useAuthStore.setState({
          token: newTokenObject,
        })

        return true
      }
      return false
    })()
    refreshResults.push(result)
  }

  return refreshResults.some(Boolean)
}

export const useRefreshWorkosToken = ({
  onError,
}: {
  onError?: () => void
}) => {
  return useQuery({
    queryKey: ["workos-health"],
    queryFn: () => refreshWorkosToken(),
    refetchInterval: 4 * 60 * 1000,
    refetchIntervalInBackground: true,
    staleTime: 2 * 60 * 1000,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    networkMode: "always",
    onError: async () => {
      const env = useAuthStore.getState().env
      await useAuthStore.getState().logout(env)
      onError && onError()
    },
  })
}
